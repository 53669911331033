$color-green: #80BC00;
$color-grey: #333333;
$color-light-grey: #636569;
$color-very-light-grey: #C3C6C8;
$color-orange: #FF9300;
$color-cyan: #00B4CF;

body {
	@include font-roboto-light;
	@include text-responsive(20px);
	color: $color-light-grey;
}

.circle-button {
	@include font-roboto-condensed;
	width: 90px;
	height: 90px;
	background-color: $color-green;
	border-radius: 100%;
	border: none;
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	@include transition;

	&--small {
		width: 60px;
		height: 60px;
	}

	&--big {
		width: 200px;
		height: 200px;
		font-size: 30px;
		@include media-breakpoint-down(lg) {
			font-size: 20px;
			width: 120px;
			height: 120px;
		}
	}

	.z-icon {
		@include transition;
	}

	&:hover {
		background-color: #fff;
		border: 1px dashed $color-green;
		color: $color-green;
		transform: scale(1.05);
	}
}

.info-service {
	position: relative;
	padding-left: 85px;

	&--white {
		color: #fff;

		.info-service_number {
			color: #fff;
		}
	}

	@include media-breakpoint-down(md) {
		padding-left: 65px;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 50px;
	}
	@include media-breakpoint-down(xs) {
		padding-left: 35px;
	}

	&_title {
		@include font-roboto-medium;
		@include title-responsive(55px);
	}

	&_number {
		@include font-roboto-bold;
		@include title-responsive(70px, 30px, .8);
		color: $color-green;
		position: absolute;
		top: 0;
		left: 0;
	}

	&_description {
		ul {
			padding-left: 20px;
		}
	}

	&_btn {
		@include font-roboto-bold;
		color: #636569;
		border: 1px solid #636569;
		border-radius: 10px;
		background: none;
		padding: 8px 25px;
		font-size: 20px;
		@include transition;

		&:hover {
			background-color: $color-green;
			color: #fff;
			border-color: #fff;
		}
	}
}

.swiper-container {
	max-width: 100%;
}

.swiper-pagination-bullets {
	display: flex;
	align-items: center;
	justify-content: center;
}

.swiper-pagination-bullet {
	width: 15px;
	height: 15px;
	background-color: transparent;
	border: 2px solid #000;
	margin-right: 5px;
	@include transition(.3s)
}

.swiper-pagination-bullet-active {
	background-color: $color-grey;
	width: 10px;
	height: 10px;
	border: none;
}

.swiper-transition-linear {
	transition-timing-function: linear;
	transition-duration: 10s;
}

//Element custom
.el-input, .el-textarea {
	&__inner {
		border-radius: 0;
		border: none;
		border-bottom: 1px solid #ddd;

		&::placeholder {
			@include font-roboto-condensed;
			font-size: 15px;
			color: #636569;
		}

		&:focus {
			border-color: $color-green;
		}
	}
}

.el-form--label-top .el-form-item__label {
	@include font-roboto-condensed;
	padding-left: 15px;
	font-size: 15px;
	color: #a8acb5;
}

.el-dialog {
	width: 90%;
	max-width: 800px;

	.el-dialog__header {
		background-color: $color-green;
		padding: 20px;
		text-align: center;

		.el-dialog__title {
			@include title-responsive(27px);
			@include font-roboto-condensed;
			color: #fff;
		}

		.el-dialog__headerbtn .el-dialog__close {
			color: #fff;
			font-size: 27px;
		}
	}
}

.el-checkbox__input.is-checked + .el-checkbox__label {
	color: $color-green;
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: $color-green;
	border-color: $color-green;
}

.custom-radio {
	.el-radio__input.is-checked .el-radio__inner {
		border-color: $color-light-grey;
		background: #fff;
	}

	.el-radio__inner {
		width: 16px;
		height: 16px;
		position: relative;
		justify-content: center;
		align-items: center;
	}

	.el-radio__input.is-checked .el-radio__inner::after {
		background-color: $color-cyan;
		width: 10px;
		height: 10px;
	}
}