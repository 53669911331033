.home-page {
	.logo-panel {
		position: relative;
		background: $color-green;
		min-height: 85px;
		@include media-breakpoint-down(md) {
			position: fixed;
			z-index: 1000;
		}

		&_logo {
			width: 300px;
			@include media-breakpoint-down(md) {
				width: 60px;
			}
		}

		&_icons {
			position: absolute;
			bottom: 10px;
			left: 10px;
			display: flex;
			flex-direction: column;

			a {
				@include transition;

				&:hover {
					opacity: .6;
				}
			}
		}

		&_cta {
			position: absolute;
			bottom: 20px;
			right: 40px;
		}
	}

	.main-banner {
		background: 50% center no-repeat;
		background-size: cover;
		@include media-breakpoint-down(lg) {
			height: 100vh;
		}

		&_title {
			@include title-responsive(110px, 30px, .7);
			@include font-roboto-bold;
			color: #fff;
			line-height: 100px;

			&--stroked {
				@include title-responsive(120px, 30px, .7);
				-webkit-text-stroke: 2px white;
				-webkit-text-fill-color: transparent;
			}
		}

		&_icon {
			margin-top: 40px;
			transform: translateX(10px) scale(1.5);
		}

		&_vertical-title {
			text-align: right;

			img {
				height: 100vh;
				@include media-breakpoint-down(lg) {
					margin-top: 90px;
					height: calc(100vh - 250px);
				}
			}
		}
	}

	.new-section {
		@include media-breakpoint-up(lg) {
			background: url(../imgs/bg-portfolio.png) right 90% no-repeat;
		}

		&_title {
			@include font-roboto-thin;
			@include title-responsive(105px, 30px, .8);

			span {
				@include font-roboto-bold-condensed;
				@include title-responsive(113px, 30px, .8);
			}
		}

		&_subtitle {
			@include font-roboto-medium;
			@include title-responsive(25px);
			color: $color-green;
		}

		&_text {
			@include font-roboto-light;
			@include text-responsive(20px);
		}

		&_carousel {
			height: 100vh;
			@include media-breakpoint-down(md) {
				height: auto;
			}
		}

		&_navigation {
			.swiper-button-prev, .swiper-button-next {
				opacity: .6;
			}

			.swiper-button-disabled {
				opacity: .4;
			}

			@include media-breakpoint-up(lg) {
				position: absolute;
				right: -60px;
				bottom: 130px;
				display: flex;
				flex-direction: column;
				.swiper-button-prev, .swiper-button-next {
					position: static;
				}
				.swiper-button-prev {
					transform: rotate(90deg);
				}
				.swiper-button-next {
					margin-top: 30px;
					transform: rotate(90deg);
				}
			}
		}
	}

	.contact-section {
		position: relative;

		&:after {
			left: -80px;
			top: 50%;
			transform: translateY(-50%);
			background-color: $color-light-grey;
			content: "";
			position: absolute;
			width: 8px;
			height: 70%;
		}
	}

	.services-section {
		&_title {
			@include title-responsive(30px);
			@include font-roboto-condensed;
			color: $color-green;
		}

		&_subtitle {
			@include title-responsive(50px);
			@include font-roboto-thin;

			span:nth-child(2) {
				@include font-roboto-bold-condensed;
				@include title-responsive(90px);
			}

			span:nth-child(4) {
				@include font-roboto;
				@include title-responsive(70px);
			}

			span:nth-child(5) {
				@include font-roboto-black;
				@include title-responsive(70px);
			}
		}

		&_banner {
			background: url("../imgs/banner-services.png") 0 0 repeat-y;
			background-size: 100%;
			animation-name: animatedBackground;
			animation-duration: 150s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}

		&_service {
			@include text-responsive(18px);
			background-color: #fff;

			&_title {
				@include title-responsive(30px);
				@include font-roboto-condensed;
				color: $color-green;
				margin: 0;
			}

			.circle-button {
				right: 10px;
				bottom: 10px;
			}
		}

		&_icon {
			@include media-breakpoint-down(md) {
				img {
					width: 100px;
				}
			}
		}

		&_points-list {
			@include font-roboto-bold-condensed;
			@include text-responsive(45px);
			-webkit-text-stroke: 1px $color-light-grey;
			-webkit-text-fill-color: transparent;

			li:last-child {
				-webkit-text-stroke: 0 #fff;
				-webkit-text-fill-color: $color-green;
			}
		}
	}

	.blog-section {
		&_title {
			@include font-roboto-medium;
			@include title-responsive(60px);
		}

		&_post {
			position: relative;
			display: flex;
			padding: 10px 10px 7px 10px;
			margin-left: -5px;
			@include transition(.3s);

			&:hover {
				color: #fff;
				background-color: $color-light-grey;
			}

			@include media-breakpoint-down(sm) {
				align-items: center;
				flex-direction: column-reverse;
			}

			&_date {
				@include font-roboto-condensed;
				@include text-responsive(15px, 12px);
				color: #C5C6C7;
			}

			&_title {
				@include font-roboto;
				@include text-responsive(23px);
			}

			&_icon {
				position: absolute;
				bottom: 1rem;
				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
		}
	}
}

.company-page {
	.title-section {
		&_title {
			span:nth-child(1) {
				@include font-roboto-black;
				@include title-responsive(100px);
			}

			span:nth-child(3) {
				@include title-responsive(120px, 30px, .7);
				-webkit-text-stroke: 1px $color-grey;
				-webkit-text-fill-color: transparent;
			}

			span:nth-child(5) {
				@include font-roboto-bold;
				@include title-responsive(65px);
			}

			span:nth-child(7) {
				@include font-roboto-medium;
				@include title-responsive(120px);
			}

			span:nth-child(9) {
				@include font-roboto-bold;
				@include title-responsive(80px);
			}

			span:nth-child(10) {
				@include font-roboto-light;
				@include title-responsive(80px);
			}
		}
	}

	.banner-section {
		@include font-caveat;
		@include title-responsive(64px);
		color: #fff;
		max-width: 1070px;
		flex: 0 0 1070px;

		&_banner {
			background: url(../imgs/bg-empresa.png) center center no-repeat;
			background-size: cover;
		}

		&_weare {
			height: 42vh;
		}

		&_zipvisual {
			height: 58vh;
		}

		&_title {
			@include font-roboto-black;
			@include title-responsive(100px);
			color: #fff;

			&_stroked {
				-webkit-text-stroke: 1px #fff;
				-webkit-text-fill-color: transparent;
			}
		}
	}

	.brochure-section {
		&_message {
			@include font-caveat;
			@include title-responsive(80px);
			color: #fff;
			background: url(../imgs/bg-brochure.png) center center no-repeat;
			background-size: cover;
			width: 80vh;
			max-width: 800px;
			max-height: 800px;
			height: 80vh;

			display: flex;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-down(md) {
				width: 80vw;
				height: 80vw;
			}

			&_min {
				@include title-responsive(55px);
				position: relative;
				right: 50%;
			}
		}

		&_button {
			@extend .circle-button;
			@include text-responsive(18px);
			background-color: #fff;
			color: $color-light-grey;
			flex-direction: column;
			display: inline-flex;
		}
	}

	.objectives-section {
		background-color: $color-green;
		color: #fff;

		&_title {
			@include font-roboto-condensed;
			@include title-responsive(80px);
		}

		&_list {
			line-height: 2rem;
			margin-left: 1.5rem;
		}
	}

	.mv-section {
		&_title {
			@include font-roboto-thin;
			@include title-responsive(60px);
		}
	}
}

.contact-page {
	.begin-section {
		color: $color-green;

		&_title {
			@include font-roboto-condensed;
			@include title-responsive(35px, 25px, .8);
		}

		&_phone {
			@include font-roboto-bold-condensed;
			@include title-responsive(65px);
		}

		&_icon {
			@include text-responsive(70px);
		}

		&_email {
			@include font-roboto-condensed;
			@include text-responsive(35px);
		}
	}

	.map-section {
		padding: 100px 180px;
		background-color: $color-light-grey;
		@include media-breakpoint-down(md) {
			padding: 15px 20px;
		}

		&_map {
			border: 10px solid $color-green;
			border-radius: 30px;
			@include media-breakpoint-down(md) {
				height: 400px;
			}
		}
	}
}

.design-page {
	.title {
		@include font-roboto-black;
		@include title-responsive(130px, 30px, .55);
		-webkit-text-stroke: 1px $color-light-grey;
		-webkit-text-fill-color: transparent;

		span {
			@include title-responsive(70px, 30px, .6);
			-webkit-text-fill-color: $color-light-grey;
		}
	}

	.bg-zip {
		background: url("../imgs/bg-design-content.png") center center no-repeat;
	}

	.bg-zip2 {
		background: url("../imgs/bg-design-content2.png") center center no-repeat;
	}

	.banner-section {
		background: url("../imgs/bg-design.png") center center no-repeat;
		background-size: cover;
		min-height: 150px;

		&_image {
			position: absolute;
			bottom: 15px;
			left: -100px;
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	.banner-section-2 {
		background: url("../imgs/bg-design-other.png") center center no-repeat;
		background-size: cover;
		min-height: 300px;
	}

	.brochure-button {
		width: 150px;
		height: 150px;
		flex-direction: column;

		> .z-icon {
			margin-top: 1rem;
			font-size: 30px;
		}
	}

	.bars-section {
		position: absolute;
		bottom: 20px;
		left: 0;
		width: 110%;
		z-index: 1;
		@include media-breakpoint-down(md) {
			position: static;
			width: 130%;
		}

		.bar {
			height: 15px;
			margin-top: 10px;

			&_c {
				background-color: #009BDD;
			}

			&_m {
				background-color: #D90080;
			}

			&_y {
				background-color: #FFEE00;
			}

			&_k {
				background-color: #1F130F;
			}
		}
	}
}

.development-page {
	.title-section {
		&_title {
			@include title-responsive(120px, 30px, .7);
			@include font-roboto-black;

			&_stroked {
				-webkit-text-stroke: 1px $color-grey;
				-webkit-text-fill-color: transparent;
			}
		}

		&_subtitle {
			@include title-responsive(80px);
			@include font-roboto-black;
		}
	}

	.computer-section {
		background: url("../imgs/bg-development-computer.png") 160px center no-repeat;
		background-size: cover;
		min-height: 150px;
		@include media-breakpoint-down(lg) {
			background: url("../imgs/bg-development-computer.png") center center no-repeat;
			background-size: cover;
		}

		&_mouse {
			position: absolute;
			left: 0;
			top: 40%;
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
	}

	.apps-section {
		background: url("../imgs/bg-development-apps.png") center center no-repeat;
		background-size: cover;


		&_icons {
			font-size: 40px;
			color: #fff;
		}
	}

	.brochure-section {
		background: url("../imgs/bg-development-responsive.png") center center no-repeat;
		background-size: cover;
	}
}

.marketing-page {
	.title-section {
		&_title {
			@include title-responsive(110px, 30px, .8);
			@include font-roboto-black;

			&_stroked {
				-webkit-text-stroke: 1px $color-grey;
				-webkit-text-fill-color: transparent;
			}
		}
	}

	.banner-section {
		background: url("../imgs/bg-marketing.png") center center no-repeat;
		background-size: cover;
		min-height: 150px;
	}

	.adwords-section {
		position: relative;
		background: url("../imgs/bg-awords.png") center center no-repeat;
		background-size: cover;

		&_icon {
			position: absolute;
			right: 50px;
			bottom: 50px;
			@include media-breakpoint-down(md) {
				opacity: .6;
			}
		}
	}

	.media-section {
		animation: bgMediaSection infinite alternate 1.5s ease-in-out;
		@include media-breakpoint-down(md) {
			animation: none;
		}
	}

	.seo-section {
		animation: bgSeoSection infinite alternate 1.5s ease-in-out;
		@include media-breakpoint-down(md) {
			animation: none;
		}
	}

	.marketing-section {
		animation: bgMarketingSection infinite alternate-reverse 1.5s ease-in-out;
		@include media-breakpoint-down(md) {
			animation: none;
		}
	}

	.brochure-section {
		background: url("../imgs/bg-development-brochure.png") center center no-repeat;
		background-size: cover;
	}
}

.portfolio-page {
	.title-verticalbox {
		transform: rotate(270deg);
		white-space: nowrap;
		@include media-breakpoint-down(md) {
			transform: none;
			white-space: normal;
		}
	}

	.title {
		@include title-responsive(75px, 36px);
		@include font-roboto-black;
		-webkit-text-stroke: 1px $color-grey;
		-webkit-text-fill-color: transparent;
	}

	.subtitle {
		@include title-responsive(40px);
		@include font-roboto-black;
	}

	.projects_carousel {
		height: 100vh;
		@include media-breakpoint-down(md) {
			height: auto;
		}
	}

	.projects-aside {
		background: url(../imgs/icon-portfolio-white.png) center right no-repeat $color-green;
	}

	.vertical-navigation {
		@include media-breakpoint-down(md) {
			.swiper-button-white, .swiper-button-black {
				--swiper-navigation-color: $color-green;
			}
		}

		.swiper-button-prev, .swiper-button-next {
			opacity: .6;
		}

		.swiper-button-disabled {
			opacity: .4;
		}

		@include media-breakpoint-up(lg) {
			position: absolute;
			right: -60px;
			bottom: 130px;
			display: flex;
			flex-direction: column;
			.swiper-button-prev, .swiper-button-next {
				position: static;
			}
			.swiper-button-prev {
				transform: rotate(90deg);
			}
			.swiper-button-next {
				margin-top: 30px;
				transform: rotate(90deg);
			}
		}
	}
}

.hosting-page {
	.title-section {
		&_title {
			@include title-responsive(80px, 30px, .7);
			@include font-roboto-black;

			&_stroked {
				@include title-responsive(120px, 30px);
				@include font-roboto-black;
				-webkit-text-stroke: 1px $color-grey;
				-webkit-text-fill-color: transparent;
			}
		}
	}

	.domains-availability-section {
		background: url(../imgs/bg-hosting-domains.png) center center no-repeat;
		background-size: cover;
		color: #fff;

		&_form {
			.el-form-item {
				display: inline-block;
				max-width: 100%;
			}

			.el-input {
				width: 350px;
				max-width: 100%;

				&__inner {
					width: 350px;
					max-width: 100%;
					@include font-roboto-light;
					@include text-responsive(20px);
				}
			}

			.el-select {
				.el-input {
					width: 130px;
					max-width: 100%;
				}
			}

			@include media-breakpoint-down(md) {
				.domains-availability-section_form_name {
					width: calc(50% - 50px);
				}
				.domains-availability-section_form_extension {
					width: calc(53% - 50px);

					.el-select {
						width: calc(100%);
					}
				}
			}
		}

		&_table {
			color: $color-green;
			width: 100%;
			max-width: 496px;

			&_status {
				@include font-roboto-bold;
				@include text-responsive(20px);
				width: 130px;

				&--unavailable {
					color: #c6ccd7;
				}
			}
		}

		&_price {
			width: 496px;
			max-width: 100%;
			border-top: 1px solid rgba(#fff, .3);
			border-bottom: 1px solid rgba(#fff, .3);

			&_title {
				@include font-roboto-light;
				@include text-responsive(20px);
			}

			&_number {
				@include font-roboto-bold;
				@include text-responsive(25px);
			}
		}

		.el-checkbox__label {
			color: #fff;
		}

		.is-checked .el-checkbox__label {
			color: $color-green;
		}

		&_cb {
			.el-checkbox__label {
				@include font-roboto-light;
				@include text-responsive(20px);
				color: #fff;
			}
		}

		&_arrow-btn {
			border: none;
			background-color: #fff;
			padding: 0 20px;
			font-size: 32px;
			line-height: 45px;
			border-radius: 10px;
			@include media-breakpoint-down(md) {
				margin-top: 50px;
			}
		}
	}

	.domains-section {
		&_title {
			@include font-roboto-bold;
			@include title-responsive(80px);
			color: $color-green;

			span {
				color: $color-light-grey;
			}
		}

		&_table {
			max-width: 100%;
			width: 420px;

			thead {
				@include font-roboto-medium;
				@include text-responsive(20px);

				td {
					padding-bottom: 20px;
				}
			}

			tbody {
				tr {
					border-bottom: 1px solid $color-light-grey;
				}

				td {
					padding: 4px 0;
				}
			}
		}

		&_terms {
			@include font-roboto-light;
			@include text-responsive(14px);
		}

		&_requested {
			width: 485px;
			max-width: 100%;
			height: 200px;
			overflow-y: auto;
			padding: 20px;
			border-radius: 10px;
			border: 1px solid $color-light-grey;

			&_title {
				@include font-roboto-bold;
				@include title-responsive(20px);
			}

			&_domains {
				@include font-roboto-light;
				@include text-responsive(20px);
				list-style: none;
				color: $color-green;
				padding: 0;

				&_remove {
					color: $color-light-grey;
					cursor: pointer;
					font-size: 15px;
					@include transition;

					&:hover {
						color: $color-green;
					}
				}
			}
		}
	}

	.hosting-section {
		flex: 0 0 1200px;
		max-width: 1200px;
		@include media-breakpoint-down(md) {
			flex: auto;
			width: 100%;
			max-width: 100%;
		}

		&_title {
			@include font-roboto-bold;
			@include title-responsive(80px);
			color: $color-green;

			span {
				color: $color-light-grey;
			}
		}

		&_last-radio {
			.el-radio__input + .el-radio__label {
				@include text-responsive(20px);
				color: $color-light-grey;
			}

			.el-radio__input {
				margin-bottom: 4px;
			}
		}

		&_terms {
			@include font-roboto-light;
			@include text-responsive(14px);

			&_download {
				@include font-roboto-light;
				@include text-responsive(12px);

				&_icon {
					font-size: 23px;
				}
			}
		}

		&_btn {
			border: none;
			background-color: #F7931E;
			border-radius: 15px;
			color: #fff;
			@include font-roboto-bold;
			@include text-responsive(20px);
			padding: 15px 20px;
		}

		.hosting-plan {
			width: 190px;
			text-align: center;

			&_header {
				background-color: $color-green;
				color: #fff;

				&_title {
					@include font-roboto;
					@include title-responsive(23px);

					span {
						@include font-roboto-black;
					}
				}

				&_size {
					@include font-roboto-bold;
					@include title-responsive(25px);
				}
			}

			&_footer {
				@include font-roboto-bold;
				@include text-responsive(25px);
				background-color: $color-light-grey;

				.el-radio__input + .el-radio__label {
					@include text-responsive(25px);
					color: #fff;
				}

				.el-radio__input {
					margin-bottom: 4px;
				}
			}

			&_included {
				@include text-responsive(13px);
				text-align: left;
			}
		}
	}
}

.services-page {
	//960
	.page-1500 {
		flex: 0 0 1500px;
		max-width: 1500px;
		@include media-breakpoint-down(md) {
			flex: auto;
		}
	}

	.title-section {
		background: $color-light-grey;
		color: #fff;

		&_title {
			@include font-roboto-black;
			@include title-responsive(105px);

			span {
				@include title-responsive(80px, 30px, .6);
				-webkit-text-stroke: 1px #fff;
				-webkit-text-fill-color: transparent;
			}

			.circle-button {
				justify-content: center;
				align-items: center;
				margin-left: 15px;
				width: 50px;
				height: 50px;
				position: relative;
				top: -20px;
			}
		}

		&_icons {
			position: absolute;
			left: 15px;
			bottom: 10px;
		}
	}

	.services-section {
		&_service {
			height: 25vh;
			display: flex;
			align-items: center;
			&_title {
				@include font-roboto-bold;
				@include title-responsive(40px,30px,.6);
				color: #fff;

				span {
					@include font-roboto-light;
					@include title-responsive(28px);
				}
			}
		}
	}
}

//sections shared
.newsletter-section {
	background: url("../imgs/bg-envelop.png") left center no-repeat;
	@include media-breakpoint-down(md) {
		background: none;
	}

	&::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		height: 90vh;
		width: 10px;
		background-color: $color-green;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&_title {
		@include font-roboto-medium;
		@include title-responsive(60px);
	}

	&_subtitle {
		@include font-roboto-light;
		@include text-responsive(20px);
	}
}

@keyframes bgMediaSection {
	0% {
		background: url("../imgs/bg-media-1.png") 10% 10% no-repeat,
		url("../imgs/bg-media-2.png") 19% 32% no-repeat,
		url("../imgs/bg-media-3.png") 39% 20% no-repeat,
		url("../imgs/bg-media-4.png") 47% 30% no-repeat,
		url("../imgs/bg-media-5.png") 60% 14% no-repeat,
		url("../imgs/bg-media-6.png") 70% 44% no-repeat,
		url("../imgs/bg-media-7.png") 90% 21% no-repeat;
	}
	100% {
		background: url("../imgs/bg-media-1.png") 10% 13% no-repeat,
		url("../imgs/bg-media-2.png") 19% 30% no-repeat,
		url("../imgs/bg-media-3.png") 39% 21% no-repeat,
		url("../imgs/bg-media-4.png") 47% 30.5% no-repeat,
		url("../imgs/bg-media-5.png") 60% 12% no-repeat,
		url("../imgs/bg-media-6.png") 70% 47% no-repeat,
		url("../imgs/bg-media-7.png") 90% 23% no-repeat;
	}
}

@keyframes bgSeoSection {
	0% {
		background: url("../imgs/bg-seo-animated.png") 50% 46% no-repeat;
	}
	100% {
		background: url("../imgs/bg-seo-animated.png") 50% 50% no-repeat;
	}
}

@keyframes bgMarketingSection {
	0% {
		background: url("../imgs/bg-marketing-animated.png") 50% 46% no-repeat;
	}
	100% {
		background: url("../imgs/bg-marketing-animated.png") 50% 50% no-repeat;
	}
}

@keyframes animatedBackground {
	0% {
		background-position-y: 0;
	}
	100% {
		background-position-y: 9990px;
	}
}

@keyframes backgroundScaled {
	0% {
		background-size: auto 105%;
	}
	100% {
		background-size: auto 150%;
	}
}
