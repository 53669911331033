.icon-scroll {
	width: 1em;
	height: 3.125em;
	z-index: 99999;

	.icon-arrows {
		position: absolute;
		transform: translate3d(30px, -33px, 0) rotate(-90deg);
	}

	.icon-arrows::after,
	.icon-arrows::before {
		content: '';
	}

	.icon-arrows span,
	.icon-arrows::after,
	.icon-arrows::before {
		display: block;
		width: 0.315em;
		height: 0.315em;
		border-right: 1px solid rgba(255, 255, 255, .8);
		border-bottom: 1px solid rgba(255, 255, 255, .8);
		margin: 0 0 0.125em 0.315em;
		transform: rotate(45deg);
		animation: mouse-scroll 1s infinite;
		animation-direction: alternate;
	}

	.icon-arrows::before {
		margin-top: 0.315em;
		animation-delay: .1s;
	}

	span {
		animation-delay: .2s;
	}

	.icon-arrows::after {
		animation-delay: .3s;
	}

	.mouse {
		height: 1.375em;
		width: .875em;
		border: 1px solid rgba(255, 255, 255, .8);
		border-radius: 2em;
	}

	.wheel {
		position: relative;
		display: block;
		height: 0.1875em;
		width: 0.1875em;
		margin: 0.1875em auto 0;
		background: rgba(255, 255, 255, .8);
		animation: mouse-wheel 1.2s ease infinite;
		border-radius: 50%;
	}

	@keyframes mouse-wheel {
		0% {
			opacity: 1;
			transform: translateY(0);
		}

		100% {
			opacity: 0;
			transform: translateY(.375em);
		}
	}

	@keyframes mouse-scroll {
		0% {
			opacity: 0;
		}
		50% {
			opacity: .5;
		}
		100% {
			opacity: 1;
		}
	}
}

.z-icon {
	display: inline-flex;
	align-self: center;

	svg, img {
		height: 1em;
		width: 1em;
		fill: currentColor;
		top: .125em;
		position: relative;
	}

	&--center svg, img {
		top: .27em;
	}
}

.z-menu {
	@include transition(.3s);
	//position: fixed;
	width: 100%;
	background-color: $color-grey;
	z-index: 1001;
	overflow: hidden;
	height: 0;
	top: 0;

	@include media-breakpoint-down(lg) {
		position: fixed;
	}

	&--open {
		height: 100%;
		overflow: auto;
	}

	&-loader {
		position: absolute;
		z-index: 10002;
		background-color: #fff;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	&_center-bar {
		position: absolute;
		left: calc(50vw - 4px);
		width: 8px;
		background-color: #fff;
		height: 700px;
		@include media-breakpoint-down(lg) {
			height: 600px;
		}
	}

	&_lang {
		@include font-roboto-bold;
		@include text-responsive(30px);
		position: absolute;
		left: calc(50vw);
		color: $color-green;
		background-color: #fff;
		text-align: center;
		width: 80px;
		top: -70px;
		@include media-breakpoint-down(md) {
			position: static;
			display: inline-block;
			margin-bottom: 1rem;
		}
	}

	&_item {
		@include font-roboto-medium;
		@include text-responsive(25px);
		@include transition(.2s);

		&:hover, .router-link-active {
			color: $color-green;
		}

		&--bold {
			@include font-roboto-bold;
			@include text-responsive(30px);
			@include transition(.2s);

			&--nohover {
				pointer-events: none;
			}

			&:hover, .router-link-exact-active {
				color: $color-green;
			}
		}
	}

	&_logo {
		width: 230px;
		@include media-breakpoint-down(md) {
			width: 100px;
		}
	}

	&_logo-panel_icons {
		@include font-roboto-medium;
		@include text-responsive(30px);

		a {
			@include transition;

			&:hover {
				color: $color-green;
			}
		}

		.z-icon {
			@include text-responsive(45px);
		}
	}
}

.z-begin-now {
	@include font-roboto-bold;
	@include text-responsive(20px);
	display: inline-block;

	&:hover {
		.z-begin-now_icon {
			transform: rotate(-37deg);
			right: 18px;
			top: 53px;
		}
	}

	&--white {
		color: #fff;
	}

	&_button {
		border: none;
		background-color: $color-orange;
		color: #fff;
		padding: 5px 10px;
		border-radius: 5px;
		margin-top: 10px;
	}

	&_icon {
		@include transition(.3s);
		color: $color-cyan;
		font-size: 60px;
		position: relative;
		top: 30px;
		right: 0;
	}
}

.button-menu {
	position: fixed;
	z-index: 1002;
	width: 40px;
	height: 40px;
	top: 20px;
	right: 20px;
	@include media-breakpoint-down(md) {
		top: 30px;
	}

	&:hover {
		.button-menu_bar {
			&:before {
				right: 12px;
			}

			&:after {
				left: 12px;
			}
		}
	}

	&--open {
		.button-menu_bar {
			transform-origin: center center;
			transform: rotate(-45deg) translate3d(20px, 10px, 0);
			box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0);

			&:after, &:before {
				transform: rotate(90deg);
				box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0);
			}

			&:before {
				right: 6px;
			}

			&:after {
				left: 6px;
			}
		}

		&:hover {
			.button-menu_bar {
				&:before {
					right: 6px;
				}

				&:after {
					left: 6px;
				}
			}
		}
	}

	&_bar {
		@include transition(.3s);
		box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, .2);
		background-color: $color-green;
		display: block;
		width: 4px;
		height: 40px;
		transform: rotate(45deg) translate3d(10px, -20px, 0);
		border-radius: 5px;
		@include media-breakpoint-down(md) {
			box-shadow: none;
		}

		&:after, &:before {
			@include transition(.3s);
			box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, .2);
			content: "";
			border-radius: 5px;
			display: block;
			background-color: $color-green;
			width: 4px;
			height: 30px;
			position: relative;
			@include media-breakpoint-down(md) {
				box-shadow: none;
				background-color: $color-light-grey;
			}
		}

		&:before {
			right: 9px;
			bottom: -5px;
		}

		&:after {
			left: 9px;
			bottom: 25px;
		}

		@include media-breakpoint-down(md) {
			background-color: $color-light-grey;
		}
	}
}

.z-header {
	max-width: 160px;
	flex: 0 0 160px;
	border-right: 1px solid #ddd;
	@include media-breakpoint-down(md) {
		max-width: 100%;
		width: 100%;
		flex: auto;
		border: none;
		border-bottom: 1px solid #ddd;
		height: 85px;
		position: fixed;
		background-color: #fff;
		z-index: 10;
		top: 0;
	}

	&_logo {
		width: 95px;
		@include media-breakpoint-down(md) {
			width: 55px;
		}
	}

	&_icons {
		font-size: 50px;
		padding: 0 0 2rem 1rem;

		> * {
			&:hover {
				color: $color-green;
				@include transition();
			}
		}
	}

	&_bodycontainer {
		@include media-breakpoint-down(md) {
			padding-top: 84px;
		}
	}
}

.z-gmap {
	width: 100%;
	min-height: 100%;
}

.z-project {
	position: relative;
	height: 100%;
	width: 100%;
	cursor: pointer;
	@include media-breakpoint-down(lg) {
		min-height: 50vw;
	}

	&:hover {
		.z-project_mask {
			opacity: 1;
		}
	}

	&_mask {
		@include font-roboto-bold;
		@include text-responsive(20px);
		@include transition(.4s);
		background-color: rgba(#000, .7);
		padding: 25px;
		color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;

		&_list {
			@include font-roboto-light;
			list-style: none;
			padding: 0;
		}

		&_button {
			@extend .circle-button;
			width: 45px;
			height: 45px;
		}
	}
}

.z-project-viewer {
	@include transition(.3s);
	position: fixed;
	width: 100%;
	background-color: rgba(#000, .9);
	z-index: 2000;
	overflow: hidden;
	height: 0;
	top: 0;

	&--open {
		height: 100%;
		overflow-y: auto;
		z-index: 2001;
		padding-top: 80px;
	}

	&_close {
		position: absolute;
		right: 10px;
		top: 10px;
		font-size: 50px;
		color: #fff;
		cursor: pointer;
	}

	&_title {
		@include text-responsive(17px);
		@include font-roboto-condensed;
		margin: {
			top: 30px;
			bottom: 3px;
		};
	}

	&_list {
		padding: 0;
		list-style: none;
	}

	&_body {
		color: #fff;
		max-width: 100vw;
		@include text-responsive(25px);
	}

	&_navs {

		.swiper-button-prev, .swiper-button-next {
			background-color: $color-green;
			text-align: center;
			width: 45px;
			height: 45px;
		}

		.swiper-button-prev:after, .swiper-button-next:after {
			font-size: 20px;
		}

		.swiper-button-disabled {
			background-color: $color-grey;
			opacity: 1;
		}

		@include media-breakpoint-up(lg) {
			position: absolute;
			right: -30px;
			bottom: 0;
			display: flex;
			flex-direction: column;
			.swiper-button-prev, .swiper-button-next {
				position: static;
			}
			.swiper-button-next:before {
				content: "";
				display: block;
				width: 35px;
				height: 1px;
				background-color: #fff;
				bottom: 45px;
				left: 0;
				z-index: 1;
				position: absolute;
			}
			.swiper-button-next {
				margin-top: 0;
			}
		}
	}
}

//Components sections

.we-section {
	&_title {
		@include title-responsive(100px, 30px, .7);
		@include font-roboto-bold-condensed;
		color: $color-green;

		span {
			@include title-responsive(50px);
			color: $color-light-grey;
		}
	}

	&_text {
		max-width: 500px;
	}

	&_subtitle {
		@include text-responsive(20px);
		@include font-roboto-condensed;
		color: $color-green;
	}
}

.z-footer {
	@include font-roboto-condensed;
	@include text-responsive(20px);
	flex: 0 0 300px;
	max-width: 960px;
	color: #fff;
	background-color: $color-light-grey;
	@include media-breakpoint-down(md) {
		flex: auto;
	}

	&_title {
		@include font-roboto-medium;
		@include title-responsive(35px);

		span {
			color: $color-very-light-grey;
		}
	}

	&_phone {
		@include text-responsive(30px);
	}

	&_end {
		background-color: $color-green;
	}

	&_privacy {
		@include text-responsive(15px);
		color: $color-very-light-grey;
		padding: 20px 0;
		position: relative;

		&:after {
			content: "";
			display: block;
			position: absolute;
			left: -32px;
			top: -10px;
			height: 4px;
			width: 110%;
			background-color: #fff;
		}
	}

	a {
		@include transition;

		&:hover {
			color: $color-green;
		}
	}
}

.z-contact {
	&_title {
		@include font-roboto-thin;
		@include title-responsive(75px)
	}
}

.z-phone {
	display: inline-block;
	position: relative;

	&_sun {
		position: absolute;
		top: 125px;
		right: 100px;
		animation: spin 8s linear infinite;
	}
}

.z-cloudcomputing {
	display: inline-block;
	position: relative;

	&_cloud {
		position: absolute;
		left: 55%;
		top: -80px;
		animation: float 5s ease-in-out infinite;
	}

	&_screen {
		position: relative;
		z-index: 1;
	}
}

.z-shoppingcar {
	position: relative;

	&_car {
		animation: shopcar .6s ease-in-out infinite;
	}

	&_w1 {
		position: absolute;
		bottom: -25px;
		left: 105px;
		animation: wheels .6s ease-in-out infinite;
	}

	&_w2 {
		position: absolute;
		bottom: -14px;
		right: 75px;
		animation: wheels .6s ease-in-out infinite;
	}

	&_lines {
		position: absolute;
		left: -60px;
		top: 55px;
		animation: lines .6s ease-in-out infinite;
	}

	&_pointer {
		position: relative;
		left: 140px;
		animation: float 4s ease-in-out infinite;
	}
}

.z-fingerprint {
	position: relative;
	transform: scale(.7);

	&_c1 {
		position: absolute;
		animation: spin 6s linear infinite;
		left: 10px;
		top: 133px;
	}

	&_c2 {
		position: absolute;
		animation: spin 16s linear infinite reverse;
		left: -8px;
		top: 116px;
	}
}

.k-scalable-content {
	max-width: 100%;

	&_container {
		width: max-content;
	}
}

// ANIMATIONS

@keyframes lines {
	50% {
		transform: skewX(3deg) scaleX(1.01);
		transform-origin: right;
	}
}

@keyframes shopcar {
	50% {
		transform: skewX(-3deg);
	}
}

@keyframes wheels {
	100% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes float {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0);
	}
}