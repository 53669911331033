


















































































.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: height, opacity;
	transition-timing-function: ease;
	overflow: hidden;
}

.fade-enter,
.fade-leave-active {
	opacity: 0
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
	transition-duration: 0.5s;
	transition-property: height, opacity, transform;
	transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
	overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
	opacity: 0;
	transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
	opacity: 0;
	transform: translate(-2em, 0);
}

.zoom-enter-active,
.zoom-leave-active {
	animation-duration: 0.5s;
	animation-fill-mode: both;
	animation-name: zoom;
}

.zoom-leave-active {
	animation-direction: reverse;
}

@keyframes zoom {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	100% {
		opacity: 1;
	}
}
