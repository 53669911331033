//General fixes
a,
a:hover {
	text-decoration: none;
	color: inherit;
	outline: none;
}

*, button {
	&:focus {
		outline: none;
	}
}

//Size helpers
.full-height {
	height: 100vh;

	&-sm {
		@include media-breakpoint-up(sm) {
			height: 100vh;
		}
	}

	&-md {
		@include media-breakpoint-up(md) {
			height: 100vh;
		}
	}

	&-lg {
		@include media-breakpoint-up(lg) {
			height: 100vh;
		}
	}

	&-xl {
		@include media-breakpoint-up(xl) {
			height: 100vh;
		}
	}
}

.t-0 {
	top: 0;
}

.b-0 {
	bottom: 0;
}

.r-0 {
	right: 0;
}

.l-0 {
	left: 0;
}

//Others
.bg-image {
	background: center center no-repeat;
	background-size: cover;
}

.cursor-pointer {
	cursor: pointer;
}

.transition3s {
	transition: .3s ease all;
}

.hidden {
	opacity: 0;
	pointer-events: none;
}

.image-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.i-list {
	list-style-type: none;
	margin-left: 2.5em;
	padding-left: 0;
	position: relative;

	&_icon {
		left: -2em;
		position: absolute;
		text-align: center;
		width: 2em;
		line-height: inherit;
	}
}

@mixin title-responsive($size,$min-size:30px,$interline-rate:1) {
	$md: $size * .9;
	$md: $size * .8;
	$sm: $size * .6;
	$xs: $size * .4;

	font-size: $size;
	line-height: $size * $interline-rate;
	@include media-breakpoint-down(lg) {
		font-size: if($md > $min-size, $md, $min-size);
		line-height: if($md > $min-size * $interline-rate, $md, $min-size * $interline-rate);
	}
	@include media-breakpoint-down(md) {
		font-size: if($md > $min-size, $md, $min-size);
		line-height: if($md > $min-size, $md * $interline-rate, $min-size * $interline-rate);
	}
	@include media-breakpoint-down(sm) {
		font-size: if($sm > $min-size, $sm, $min-size);
		line-height: if($sm > $min-size, $sm * $interline-rate, $min-size * $interline-rate);
	}
	@include media-breakpoint-down(xs) {
		font-size: if($xs > $min-size, $xs, $min-size);
		line-height: if($xs > $min-size, $xs * $interline-rate, $min-size * $interline-rate);
	}
}

@mixin text-responsive($size,$min-size:16px,$interline-rate:1.16) {
	$md: $size * .9;
	$sm: $size * .8;
	$xs: $size * .6;

	font-size: $size;
	line-height: $size * $interline-rate;
	@include media-breakpoint-down(lg) {
		font-size: if($md > 16px, $md, $min-size);
		line-height: if($md > 16px * $interline-rate, $md, $min-size * $interline-rate);
	}
	@include media-breakpoint-down(md) {
		font-size: if($sm > 16px, $sm, $min-size);
		line-height: if($sm > 16px * $interline-rate, $sm, $min-size * $interline-rate);
	}
	@include media-breakpoint-down(xs) {
		font-size: if($xs > 16px, $xs, $min-size);
		line-height: if($xs > 16px * $interline-rate, $xs, $min-size * $interline-rate);
	}
}

// Magic parallax mixins

//$parallax-perspective: 1 !default;
//$parallax-element: "body" !default;
//$parallax-ios: true !default;
//
//@mixin parallax-init(
//	$perspective  : $parallax-perspective,
//	$element      : $parallax-element,
//	$parallax-ios : $parallax-ios
//) {
//	@if $element == "body" {
//		html, body {
//			width: 100%;
//			height: 100%;
//			margin: 0;
//			padding: 0;
//			overflow: hidden;
//		}
//	}
//	#{$element} {
//		overflow: auto;
//		perspective: $perspective * 1px;
//		transform-style: preserve-3d;
//		// Allows for smooth scrolling but disables parallax effects.
//		@if $parallax-ios == false {
//			-webkit-overflow-scrolling: touch;
//		}
//		// Preserve 3D
//		&, * {
//			transform-style: preserve-3d;
//		}
//	}
//}
//
//@mixin parallax(
//	$distance    : 0,
//	$perspective : $parallax-perspective
//) {
//	transform: translateZ($distance * $perspective * 1px) scale(abs($distance - 1));
//	z-index: $distance * 1000;
//}
//
//.no-3d {
//	&, & * {
//		transform-style: flat !important;
//	}
//}
