.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.5rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1rem !important;
}

.m-3 {
  margin: 2rem !important;
}

.mt-3,
.my-3 {
  margin-top: 2rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 2rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 2rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 2rem !important;
}

.m-4 {
  margin: 3rem !important;
}

.mt-4,
.my-4 {
  margin-top: 3rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 3rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 3rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 3rem !important;
}

.m-5 {
  margin: 6rem !important;
}

.mt-5,
.my-5 {
  margin-top: 6rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 6rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 6rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 6rem !important;
}

.m-6 {
  margin: 9rem !important;
}

.mt-6,
.my-6 {
  margin-top: 9rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 9rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 9rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 9rem !important;
}

.m-7 {
  margin: 12rem !important;
}

.mt-7,
.my-7 {
  margin-top: 12rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 12rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 12rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 12rem !important;
}

.m-8 {
  margin: 15rem !important;
}

.mt-8,
.my-8 {
  margin-top: 15rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 15rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 15rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 15rem !important;
}

.m-9 {
  margin: 18rem !important;
}

.mt-9,
.my-9 {
  margin-top: 18rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 18rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 18rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 18rem !important;
}

.m-10 {
  margin: 21rem !important;
}

.mt-10,
.my-10 {
  margin-top: 21rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 21rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 21rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 21rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.5rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.5rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.p-3 {
  padding: 2rem !important;
}

.pt-3,
.py-3 {
  padding-top: 2rem !important;
}

.pr-3,
.px-3 {
  padding-right: 2rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 2rem !important;
}

.pl-3,
.px-3 {
  padding-left: 2rem !important;
}

.p-4 {
  padding: 3rem !important;
}

.pt-4,
.py-4 {
  padding-top: 3rem !important;
}

.pr-4,
.px-4 {
  padding-right: 3rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 3rem !important;
}

.pl-4,
.px-4 {
  padding-left: 3rem !important;
}

.p-5 {
  padding: 6rem !important;
}

.pt-5,
.py-5 {
  padding-top: 6rem !important;
}

.pr-5,
.px-5 {
  padding-right: 6rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 6rem !important;
}

.pl-5,
.px-5 {
  padding-left: 6rem !important;
}

.p-6 {
  padding: 9rem !important;
}

.pt-6,
.py-6 {
  padding-top: 9rem !important;
}

.pr-6,
.px-6 {
  padding-right: 9rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 9rem !important;
}

.pl-6,
.px-6 {
  padding-left: 9rem !important;
}

.p-7 {
  padding: 12rem !important;
}

.pt-7,
.py-7 {
  padding-top: 12rem !important;
}

.pr-7,
.px-7 {
  padding-right: 12rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 12rem !important;
}

.pl-7,
.px-7 {
  padding-left: 12rem !important;
}

.p-8 {
  padding: 15rem !important;
}

.pt-8,
.py-8 {
  padding-top: 15rem !important;
}

.pr-8,
.px-8 {
  padding-right: 15rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 15rem !important;
}

.pl-8,
.px-8 {
  padding-left: 15rem !important;
}

.p-9 {
  padding: 18rem !important;
}

.pt-9,
.py-9 {
  padding-top: 18rem !important;
}

.pr-9,
.px-9 {
  padding-right: 18rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 18rem !important;
}

.pl-9,
.px-9 {
  padding-left: 18rem !important;
}

.p-10 {
  padding: 21rem !important;
}

.pt-10,
.py-10 {
  padding-top: 21rem !important;
}

.pr-10,
.px-10 {
  padding-right: 21rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 21rem !important;
}

.pl-10,
.px-10 {
  padding-left: 21rem !important;
}

.m-n1 {
  margin: -0.5rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.5rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.5rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.5rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.5rem !important;
}

.m-n2 {
  margin: -1rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -1rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -1rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -1rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -1rem !important;
}

.m-n3 {
  margin: -2rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -2rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -2rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -2rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -2rem !important;
}

.m-n4 {
  margin: -3rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -3rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -3rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -3rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -3rem !important;
}

.m-n5 {
  margin: -6rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -6rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -6rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -6rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -6rem !important;
}

.m-n6 {
  margin: -9rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -9rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -9rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -9rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -9rem !important;
}

.m-n7 {
  margin: -12rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -12rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -12rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -12rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -12rem !important;
}

.m-n8 {
  margin: -15rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -15rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -15rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -15rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -15rem !important;
}

.m-n9 {
  margin: -18rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -18rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -18rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -18rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -18rem !important;
}

.m-n10 {
  margin: -21rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -21rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -21rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -21rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -21rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.5rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 1rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important;
  }
  .m-sm-3 {
    margin: 2rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2rem !important;
  }
  .m-sm-4 {
    margin: 3rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 3rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 3rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 3rem !important;
  }
  .m-sm-5 {
    margin: 6rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 6rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 6rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 6rem !important;
  }
  .m-sm-6 {
    margin: 9rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 9rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 9rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 9rem !important;
  }
  .m-sm-7 {
    margin: 12rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 12rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 12rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 12rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 12rem !important;
  }
  .m-sm-8 {
    margin: 15rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 15rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 15rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 15rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 15rem !important;
  }
  .m-sm-9 {
    margin: 18rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 18rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 18rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 18rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 18rem !important;
  }
  .m-sm-10 {
    margin: 21rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 21rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 21rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 21rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 21rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.5rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 1rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important;
  }
  .p-sm-3 {
    padding: 2rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2rem !important;
  }
  .p-sm-4 {
    padding: 3rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 3rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 3rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 3rem !important;
  }
  .p-sm-5 {
    padding: 6rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 6rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 6rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 6rem !important;
  }
  .p-sm-6 {
    padding: 9rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 9rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 9rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 9rem !important;
  }
  .p-sm-7 {
    padding: 12rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 12rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 12rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 12rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 12rem !important;
  }
  .p-sm-8 {
    padding: 15rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 15rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 15rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 15rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 15rem !important;
  }
  .p-sm-9 {
    padding: 18rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 18rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 18rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 18rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 18rem !important;
  }
  .p-sm-10 {
    padding: 21rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 21rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 21rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 21rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 21rem !important;
  }
  .m-sm-n1 {
    margin: -0.5rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n2 {
    margin: -1rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1rem !important;
  }
  .m-sm-n3 {
    margin: -2rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -2rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -2rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -2rem !important;
  }
  .m-sm-n4 {
    margin: -3rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -3rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -3rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -3rem !important;
  }
  .m-sm-n5 {
    margin: -6rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -6rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -6rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -6rem !important;
  }
  .m-sm-n6 {
    margin: -9rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -9rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -9rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -9rem !important;
  }
  .m-sm-n7 {
    margin: -12rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -12rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -12rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -12rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -12rem !important;
  }
  .m-sm-n8 {
    margin: -15rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -15rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -15rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -15rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -15rem !important;
  }
  .m-sm-n9 {
    margin: -18rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -18rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -18rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -18rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -18rem !important;
  }
  .m-sm-n10 {
    margin: -21rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -21rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -21rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -21rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -21rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.5rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important;
  }
  .m-md-2 {
    margin: 1rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important;
  }
  .m-md-3 {
    margin: 2rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2rem !important;
  }
  .m-md-4 {
    margin: 3rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3rem !important;
  }
  .m-md-5 {
    margin: 6rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 6rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 6rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 6rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 6rem !important;
  }
  .m-md-6 {
    margin: 9rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 9rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 9rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 9rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 9rem !important;
  }
  .m-md-7 {
    margin: 12rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 12rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 12rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 12rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 12rem !important;
  }
  .m-md-8 {
    margin: 15rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 15rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 15rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 15rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 15rem !important;
  }
  .m-md-9 {
    margin: 18rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 18rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 18rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 18rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 18rem !important;
  }
  .m-md-10 {
    margin: 21rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 21rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 21rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 21rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 21rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.5rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important;
  }
  .p-md-2 {
    padding: 1rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important;
  }
  .p-md-3 {
    padding: 2rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2rem !important;
  }
  .p-md-4 {
    padding: 3rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3rem !important;
  }
  .p-md-5 {
    padding: 6rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 6rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 6rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 6rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 6rem !important;
  }
  .p-md-6 {
    padding: 9rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 9rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 9rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 9rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 9rem !important;
  }
  .p-md-7 {
    padding: 12rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 12rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 12rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 12rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 12rem !important;
  }
  .p-md-8 {
    padding: 15rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 15rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 15rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 15rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 15rem !important;
  }
  .p-md-9 {
    padding: 18rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 18rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 18rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 18rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 18rem !important;
  }
  .p-md-10 {
    padding: 21rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 21rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 21rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 21rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 21rem !important;
  }
  .m-md-n1 {
    margin: -0.5rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.5rem !important;
  }
  .m-md-n2 {
    margin: -1rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1rem !important;
  }
  .m-md-n3 {
    margin: -2rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -2rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -2rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -2rem !important;
  }
  .m-md-n4 {
    margin: -3rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -3rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -3rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -3rem !important;
  }
  .m-md-n5 {
    margin: -6rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -6rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -6rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -6rem !important;
  }
  .m-md-n6 {
    margin: -9rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -9rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -9rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -9rem !important;
  }
  .m-md-n7 {
    margin: -12rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -12rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -12rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -12rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -12rem !important;
  }
  .m-md-n8 {
    margin: -15rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -15rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -15rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -15rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -15rem !important;
  }
  .m-md-n9 {
    margin: -18rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -18rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -18rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -18rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -18rem !important;
  }
  .m-md-n10 {
    margin: -21rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -21rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -21rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -21rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -21rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.5rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 1rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important;
  }
  .m-lg-3 {
    margin: 2rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2rem !important;
  }
  .m-lg-4 {
    margin: 3rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3rem !important;
  }
  .m-lg-5 {
    margin: 6rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 6rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 6rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 6rem !important;
  }
  .m-lg-6 {
    margin: 9rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 9rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 9rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 9rem !important;
  }
  .m-lg-7 {
    margin: 12rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 12rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 12rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 12rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 12rem !important;
  }
  .m-lg-8 {
    margin: 15rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 15rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 15rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 15rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 15rem !important;
  }
  .m-lg-9 {
    margin: 18rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 18rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 18rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 18rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 18rem !important;
  }
  .m-lg-10 {
    margin: 21rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 21rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 21rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 21rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 21rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.5rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 1rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important;
  }
  .p-lg-3 {
    padding: 2rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2rem !important;
  }
  .p-lg-4 {
    padding: 3rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3rem !important;
  }
  .p-lg-5 {
    padding: 6rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 6rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 6rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 6rem !important;
  }
  .p-lg-6 {
    padding: 9rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 9rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 9rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 9rem !important;
  }
  .p-lg-7 {
    padding: 12rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 12rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 12rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 12rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 12rem !important;
  }
  .p-lg-8 {
    padding: 15rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 15rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 15rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 15rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 15rem !important;
  }
  .p-lg-9 {
    padding: 18rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 18rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 18rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 18rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 18rem !important;
  }
  .p-lg-10 {
    padding: 21rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 21rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 21rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 21rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 21rem !important;
  }
  .m-lg-n1 {
    margin: -0.5rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n2 {
    margin: -1rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1rem !important;
  }
  .m-lg-n3 {
    margin: -2rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -2rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -2rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -2rem !important;
  }
  .m-lg-n4 {
    margin: -3rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -3rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -3rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -3rem !important;
  }
  .m-lg-n5 {
    margin: -6rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -6rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -6rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -6rem !important;
  }
  .m-lg-n6 {
    margin: -9rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -9rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -9rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -9rem !important;
  }
  .m-lg-n7 {
    margin: -12rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -12rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -12rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -12rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -12rem !important;
  }
  .m-lg-n8 {
    margin: -15rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -15rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -15rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -15rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -15rem !important;
  }
  .m-lg-n9 {
    margin: -18rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -18rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -18rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -18rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -18rem !important;
  }
  .m-lg-n10 {
    margin: -21rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -21rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -21rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -21rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -21rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.5rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 1rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important;
  }
  .m-xl-3 {
    margin: 2rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 2rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 2rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 2rem !important;
  }
  .m-xl-4 {
    margin: 3rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3rem !important;
  }
  .m-xl-5 {
    margin: 6rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 6rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 6rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 6rem !important;
  }
  .m-xl-6 {
    margin: 9rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 9rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 9rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 9rem !important;
  }
  .m-xl-7 {
    margin: 12rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 12rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 12rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 12rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 12rem !important;
  }
  .m-xl-8 {
    margin: 15rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 15rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 15rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 15rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 15rem !important;
  }
  .m-xl-9 {
    margin: 18rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 18rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 18rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 18rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 18rem !important;
  }
  .m-xl-10 {
    margin: 21rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 21rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 21rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 21rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 21rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.5rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 1rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important;
  }
  .p-xl-3 {
    padding: 2rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 2rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 2rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 2rem !important;
  }
  .p-xl-4 {
    padding: 3rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3rem !important;
  }
  .p-xl-5 {
    padding: 6rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 6rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 6rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 6rem !important;
  }
  .p-xl-6 {
    padding: 9rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 9rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 9rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 9rem !important;
  }
  .p-xl-7 {
    padding: 12rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 12rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 12rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 12rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 12rem !important;
  }
  .p-xl-8 {
    padding: 15rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 15rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 15rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 15rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 15rem !important;
  }
  .p-xl-9 {
    padding: 18rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 18rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 18rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 18rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 18rem !important;
  }
  .p-xl-10 {
    padding: 21rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 21rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 21rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 21rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 21rem !important;
  }
  .m-xl-n1 {
    margin: -0.5rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n2 {
    margin: -1rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1rem !important;
  }
  .m-xl-n3 {
    margin: -2rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -2rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -2rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -2rem !important;
  }
  .m-xl-n4 {
    margin: -3rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -3rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -3rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -3rem !important;
  }
  .m-xl-n5 {
    margin: -6rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -6rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -6rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -6rem !important;
  }
  .m-xl-n6 {
    margin: -9rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -9rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -9rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -9rem !important;
  }
  .m-xl-n7 {
    margin: -12rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -12rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -12rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -12rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -12rem !important;
  }
  .m-xl-n8 {
    margin: -15rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -15rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -15rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -15rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -15rem !important;
  }
  .m-xl-n9 {
    margin: -18rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -18rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -18rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -18rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -18rem !important;
  }
  .m-xl-n10 {
    margin: -21rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -21rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -21rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -21rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -21rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 1rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 2rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

/**
Fix for layout in horizontal mode
 */
@media (min-width: 768px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container {
    padding: 0;
  }
  .row {
    margin: 0;
  }
  .col {
    padding: 0;
  }
}

.layout-horizontal {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .layout-horizontal {
    width: 100%;
    height: auto;
    overflow: auto;
  }
}

.layout-horizontal_container {
  width: calc(100vh + 18px);
  height: 100vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (max-width: 991.98px) {
  .layout-horizontal_container {
    transform: none;
    height: auto;
    width: 100%;
    overflow: hidden;
  }
}

.layout-horizontal_wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  transform: rotate(90deg) translateY(-100vh) scale(1);
  transform-origin: top left;
}

@media (max-width: 991.98px) {
  .layout-horizontal_wrapper {
    width: 100%;
    height: auto;
    transform: none;
    display: block;
  }
}

.layout-horizontal_page {
  flex: 0 0 1920px;
  max-width: 1920px;
}

@media (max-width: 991.98px) {
  .layout-horizontal_page {
    flex: auto;
  }
}

.layout-horizontal_middle-page {
  flex: 0 0 960px;
  max-width: 960px;
}

@media (max-width: 991.98px) {
  .layout-horizontal_middle-page {
    flex: auto;
  }
}

.layout-horizontal_600-page {
  flex: 0 0 600px;
  max-width: 600px;
}

@media (max-width: 991.98px) {
  .layout-horizontal_600-page {
    max-width: none;
    flex: auto;
  }
}

@font-face {
  font-family: 'Roboto';
  src: url("Roboto-Medium.woff2") format("woff2"), url("Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("Roboto-Bold.woff2") format("woff2"), url("Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("Roboto-Thin.woff2") format("woff2"), url("Roboto-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("Roboto-Black.woff2") format("woff2"), url("Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Cn';
  src: url("Roboto-Condensed.woff2") format("woff2"), url("Roboto-Condensed.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("Roboto-Regular.woff2") format("woff2"), url("Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Cn';
  src: url("Roboto-BoldCondensed.woff2") format("woff2"), url("Roboto-BoldCondensed.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("Roboto-Light.woff2") format("woff2"), url("Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Caveat';
  src: url("Caveat-Regular.woff2") format("woff2"), url("Caveat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
  outline: none;
}

*:focus, button:focus {
  outline: none;
}

.full-height {
  height: 100vh;
}

@media (min-width: 576px) {
  .full-height-sm {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .full-height-md {
    height: 100vh;
  }
}

@media (min-width: 992px) {
  .full-height-lg {
    height: 100vh;
  }
}

@media (min-width: 1200px) {
  .full-height-xl {
    height: 100vh;
  }
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.r-0 {
  right: 0;
}

.l-0 {
  left: 0;
}

.bg-image {
  background: center center no-repeat;
  background-size: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.transition3s {
  transition: .3s ease all;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.i-list {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
  position: relative;
}

.i-list_icon {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

body {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
  color: #636569;
}

@media (max-width: 1199.98px) {
  body {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  body {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  body {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.circle-button, .z-project_mask_button, .company-page .brochure-section_button {
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  width: 90px;
  height: 90px;
  background-color: #80BC00;
  border-radius: 100%;
  border: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .circle-button, .z-project_mask_button, .company-page .brochure-section_button {
    transition: none;
  }
}

.circle-button--small {
  width: 60px;
  height: 60px;
}

.circle-button--big {
  width: 200px;
  height: 200px;
  font-size: 30px;
}

@media (max-width: 1199.98px) {
  .circle-button--big {
    font-size: 20px;
    width: 120px;
    height: 120px;
  }
}

.circle-button .z-icon, .z-project_mask_button .z-icon, .company-page .brochure-section_button .z-icon {
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .circle-button .z-icon, .z-project_mask_button .z-icon, .company-page .brochure-section_button .z-icon {
    transition: none;
  }
}

.circle-button:hover, .z-project_mask_button:hover, .company-page .brochure-section_button:hover {
  background-color: #fff;
  border: 1px dashed #80BC00;
  color: #80BC00;
  transform: scale(1.05);
}

.info-service {
  position: relative;
  padding-left: 85px;
}

.info-service--white {
  color: #fff;
}

.info-service--white .info-service_number {
  color: #fff;
}

@media (max-width: 991.98px) {
  .info-service {
    padding-left: 65px;
  }
}

@media (max-width: 767.98px) {
  .info-service {
    padding-left: 50px;
  }
}

@media (max-width: 575.98px) {
  .info-service {
    padding-left: 35px;
  }
}

.info-service_title {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 55px;
  line-height: 55px;
}

@media (max-width: 1199.98px) {
  .info-service_title {
    font-size: 44px;
    line-height: 44px;
  }
}

@media (max-width: 991.98px) {
  .info-service_title {
    font-size: 44px;
    line-height: 44px;
  }
}

@media (max-width: 767.98px) {
  .info-service_title {
    font-size: 33px;
    line-height: 33px;
  }
}

@media (max-width: 575.98px) {
  .info-service_title {
    font-size: 30px;
    line-height: 30px;
  }
}

.info-service_number {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 70px;
  line-height: 56px;
  color: #80BC00;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1199.98px) {
  .info-service_number {
    font-size: 56px;
    line-height: 56px;
  }
}

@media (max-width: 991.98px) {
  .info-service_number {
    font-size: 56px;
    line-height: 44.8px;
  }
}

@media (max-width: 767.98px) {
  .info-service_number {
    font-size: 42px;
    line-height: 33.6px;
  }
}

@media (max-width: 575.98px) {
  .info-service_number {
    font-size: 30px;
    line-height: 24px;
  }
}

.info-service_description ul {
  padding-left: 20px;
}

.info-service_btn {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  color: #636569;
  border: 1px solid #636569;
  border-radius: 10px;
  background: none;
  padding: 8px 25px;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .info-service_btn {
    transition: none;
  }
}

.info-service_btn:hover {
  background-color: #80BC00;
  color: #fff;
  border-color: #fff;
}

.swiper-container {
  max-width: 100%;
}

.swiper-pagination-bullets {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 2px solid #000;
  margin-right: 5px;
  transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .swiper-pagination-bullet {
    transition: none;
  }
}

.swiper-pagination-bullet-active {
  background-color: #333333;
  width: 10px;
  height: 10px;
  border: none;
}

.swiper-transition-linear {
  transition-timing-function: linear;
  transition-duration: 10s;
}

.el-input__inner, .el-textarea__inner {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ddd;
}

.el-input__inner::placeholder, .el-textarea__inner::placeholder {
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  color: #636569;
}

.el-input__inner:focus, .el-textarea__inner:focus {
  border-color: #80BC00;
}

.el-form--label-top .el-form-item__label {
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  padding-left: 15px;
  font-size: 15px;
  color: #a8acb5;
}

.el-dialog {
  width: 90%;
  max-width: 800px;
}

.el-dialog .el-dialog__header {
  background-color: #80BC00;
  padding: 20px;
  text-align: center;
}

.el-dialog .el-dialog__header .el-dialog__title {
  font-size: 27px;
  line-height: 27px;
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  color: #fff;
}

@media (max-width: 1199.98px) {
  .el-dialog .el-dialog__header .el-dialog__title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 991.98px) {
  .el-dialog .el-dialog__header .el-dialog__title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 767.98px) {
  .el-dialog .el-dialog__header .el-dialog__title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .el-dialog .el-dialog__header .el-dialog__title {
    font-size: 30px;
    line-height: 30px;
  }
}

.el-dialog .el-dialog__header .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
  font-size: 27px;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #80BC00;
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #80BC00;
  border-color: #80BC00;
}

.custom-radio .el-radio__input.is-checked .el-radio__inner {
  border-color: #636569;
  background: #fff;
}

.custom-radio .el-radio__inner {
  width: 16px;
  height: 16px;
  position: relative;
  justify-content: center;
  align-items: center;
}

.custom-radio .el-radio__input.is-checked .el-radio__inner::after {
  background-color: #00B4CF;
  width: 10px;
  height: 10px;
}

.icon-scroll {
  width: 1em;
  height: 3.125em;
  z-index: 99999;
}

.icon-scroll .icon-arrows {
  position: absolute;
  transform: translate3d(30px, -33px, 0) rotate(-90deg);
}

.icon-scroll .icon-arrows::after,
.icon-scroll .icon-arrows::before {
  content: '';
}

.icon-scroll .icon-arrows span,
.icon-scroll .icon-arrows::after,
.icon-scroll .icon-arrows::before {
  display: block;
  width: 0.315em;
  height: 0.315em;
  border-right: 1px solid rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  margin: 0 0 0.125em 0.315em;
  transform: rotate(45deg);
  animation: mouse-scroll 1s infinite;
  animation-direction: alternate;
}

.icon-scroll .icon-arrows::before {
  margin-top: 0.315em;
  animation-delay: .1s;
}

.icon-scroll span {
  animation-delay: .2s;
}

.icon-scroll .icon-arrows::after {
  animation-delay: .3s;
}

.icon-scroll .mouse {
  height: 1.375em;
  width: .875em;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 2em;
}

.icon-scroll .wheel {
  position: relative;
  display: block;
  height: 0.1875em;
  width: 0.1875em;
  margin: 0.1875em auto 0;
  background: rgba(255, 255, 255, 0.8);
  animation: mouse-wheel 1.2s ease infinite;
  border-radius: 50%;
}

@keyframes mouse-wheel {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(0.375em);
  }
}

@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.z-icon {
  display: inline-flex;
  align-self: center;
}

.z-icon svg, .z-icon img {
  height: 1em;
  width: 1em;
  fill: currentColor;
  top: .125em;
  position: relative;
}

.z-icon--center svg, .z-icon img {
  top: .27em;
}

.z-menu {
  transition: 0.3s;
  width: 100%;
  background-color: #333333;
  z-index: 1001;
  overflow: hidden;
  height: 0;
  top: 0;
}

@media (prefers-reduced-motion: reduce) {
  .z-menu {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .z-menu {
    position: fixed;
  }
}

.z-menu--open {
  height: 100%;
  overflow: auto;
}

.z-menu-loader {
  position: absolute;
  z-index: 10002;
  background-color: #fff;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.z-menu_center-bar {
  position: absolute;
  left: calc(50vw - 4px);
  width: 8px;
  background-color: #fff;
  height: 700px;
}

@media (max-width: 1199.98px) {
  .z-menu_center-bar {
    height: 600px;
  }
}

.z-menu_lang {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 30px;
  line-height: 34.8px;
  position: absolute;
  left: calc(50vw);
  color: #80BC00;
  background-color: #fff;
  text-align: center;
  width: 80px;
  top: -70px;
}

@media (max-width: 1199.98px) {
  .z-menu_lang {
    font-size: 27px;
    line-height: 27px;
  }
}

@media (max-width: 991.98px) {
  .z-menu_lang {
    font-size: 24px;
    line-height: 24px;
  }
}

@media (max-width: 575.98px) {
  .z-menu_lang {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .z-menu_lang {
    position: static;
    display: inline-block;
    margin-bottom: 1rem;
  }
}

.z-menu_item {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  line-height: 29px;
  transition: 0.2s;
}

@media (max-width: 1199.98px) {
  .z-menu_item {
    font-size: 22.5px;
    line-height: 22.5px;
  }
}

@media (max-width: 991.98px) {
  .z-menu_item {
    font-size: 20px;
    line-height: 20px;
  }
}

@media (max-width: 575.98px) {
  .z-menu_item {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .z-menu_item {
    transition: none;
  }
}

.z-menu_item:hover, .z-menu_item .router-link-active {
  color: #80BC00;
}

.z-menu_item--bold {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 30px;
  line-height: 34.8px;
  transition: 0.2s;
}

@media (max-width: 1199.98px) {
  .z-menu_item--bold {
    font-size: 27px;
    line-height: 27px;
  }
}

@media (max-width: 991.98px) {
  .z-menu_item--bold {
    font-size: 24px;
    line-height: 24px;
  }
}

@media (max-width: 575.98px) {
  .z-menu_item--bold {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .z-menu_item--bold {
    transition: none;
  }
}

.z-menu_item--bold--nohover {
  pointer-events: none;
}

.z-menu_item--bold:hover, .z-menu_item--bold .router-link-exact-active {
  color: #80BC00;
}

.z-menu_logo {
  width: 230px;
}

@media (max-width: 991.98px) {
  .z-menu_logo {
    width: 100px;
  }
}

.z-menu_logo-panel_icons {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 30px;
  line-height: 34.8px;
}

@media (max-width: 1199.98px) {
  .z-menu_logo-panel_icons {
    font-size: 27px;
    line-height: 27px;
  }
}

@media (max-width: 991.98px) {
  .z-menu_logo-panel_icons {
    font-size: 24px;
    line-height: 24px;
  }
}

@media (max-width: 575.98px) {
  .z-menu_logo-panel_icons {
    font-size: 18px;
    line-height: 18.56px;
  }
}

.z-menu_logo-panel_icons a {
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .z-menu_logo-panel_icons a {
    transition: none;
  }
}

.z-menu_logo-panel_icons a:hover {
  color: #80BC00;
}

.z-menu_logo-panel_icons .z-icon {
  font-size: 45px;
  line-height: 52.2px;
}

@media (max-width: 1199.98px) {
  .z-menu_logo-panel_icons .z-icon {
    font-size: 40.5px;
    line-height: 40.5px;
  }
}

@media (max-width: 991.98px) {
  .z-menu_logo-panel_icons .z-icon {
    font-size: 36px;
    line-height: 36px;
  }
}

@media (max-width: 575.98px) {
  .z-menu_logo-panel_icons .z-icon {
    font-size: 27px;
    line-height: 27px;
  }
}

.z-begin-now {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
  display: inline-block;
}

@media (max-width: 1199.98px) {
  .z-begin-now {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .z-begin-now {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .z-begin-now {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.z-begin-now:hover .z-begin-now_icon {
  transform: rotate(-37deg);
  right: 18px;
  top: 53px;
}

.z-begin-now--white {
  color: #fff;
}

.z-begin-now_button {
  border: none;
  background-color: #FF9300;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.z-begin-now_icon {
  transition: 0.3s;
  color: #00B4CF;
  font-size: 60px;
  position: relative;
  top: 30px;
  right: 0;
}

@media (prefers-reduced-motion: reduce) {
  .z-begin-now_icon {
    transition: none;
  }
}

.button-menu {
  position: fixed;
  z-index: 1002;
  width: 40px;
  height: 40px;
  top: 20px;
  right: 20px;
}

@media (max-width: 991.98px) {
  .button-menu {
    top: 30px;
  }
}

.button-menu:hover .button-menu_bar:before {
  right: 12px;
}

.button-menu:hover .button-menu_bar:after {
  left: 12px;
}

.button-menu--open .button-menu_bar {
  transform-origin: center center;
  transform: rotate(-45deg) translate3d(20px, 10px, 0);
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0);
}

.button-menu--open .button-menu_bar:after, .button-menu--open .button-menu_bar:before {
  transform: rotate(90deg);
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0);
}

.button-menu--open .button-menu_bar:before {
  right: 6px;
}

.button-menu--open .button-menu_bar:after {
  left: 6px;
}

.button-menu--open:hover .button-menu_bar:before {
  right: 6px;
}

.button-menu--open:hover .button-menu_bar:after {
  left: 6px;
}

.button-menu_bar {
  transition: 0.3s;
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
  background-color: #80BC00;
  display: block;
  width: 4px;
  height: 40px;
  transform: rotate(45deg) translate3d(10px, -20px, 0);
  border-radius: 5px;
}

@media (prefers-reduced-motion: reduce) {
  .button-menu_bar {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .button-menu_bar {
    box-shadow: none;
  }
}

.button-menu_bar:after, .button-menu_bar:before {
  transition: 0.3s;
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
  content: "";
  border-radius: 5px;
  display: block;
  background-color: #80BC00;
  width: 4px;
  height: 30px;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .button-menu_bar:after, .button-menu_bar:before {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .button-menu_bar:after, .button-menu_bar:before {
    box-shadow: none;
    background-color: #636569;
  }
}

.button-menu_bar:before {
  right: 9px;
  bottom: -5px;
}

.button-menu_bar:after {
  left: 9px;
  bottom: 25px;
}

@media (max-width: 991.98px) {
  .button-menu_bar {
    background-color: #636569;
  }
}

.z-header {
  max-width: 160px;
  flex: 0 0 160px;
  border-right: 1px solid #ddd;
}

@media (max-width: 991.98px) {
  .z-header {
    max-width: 100%;
    width: 100%;
    flex: auto;
    border: none;
    border-bottom: 1px solid #ddd;
    height: 85px;
    position: fixed;
    background-color: #fff;
    z-index: 10;
    top: 0;
  }
}

.z-header_logo {
  width: 95px;
}

@media (max-width: 991.98px) {
  .z-header_logo {
    width: 55px;
  }
}

.z-header_icons {
  font-size: 50px;
  padding: 0 0 2rem 1rem;
}

.z-header_icons > *:hover {
  color: #80BC00;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .z-header_icons > *:hover {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .z-header_bodycontainer {
    padding-top: 84px;
  }
}

.z-gmap {
  width: 100%;
  min-height: 100%;
}

.z-project {
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

@media (max-width: 1199.98px) {
  .z-project {
    min-height: 50vw;
  }
}

.z-project:hover .z-project_mask {
  opacity: 1;
}

.z-project_mask {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
  transition: 0.4s;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 25px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

@media (max-width: 1199.98px) {
  .z-project_mask {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .z-project_mask {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .z-project_mask {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .z-project_mask {
    transition: none;
  }
}

.z-project_mask_list {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  list-style: none;
  padding: 0;
}

.z-project_mask_button {
  width: 45px;
  height: 45px;
}

.z-project-viewer {
  transition: 0.3s;
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2000;
  overflow: hidden;
  height: 0;
  top: 0;
}

@media (prefers-reduced-motion: reduce) {
  .z-project-viewer {
    transition: none;
  }
}

.z-project-viewer--open {
  height: 100%;
  overflow-y: auto;
  z-index: 2001;
  padding-top: 80px;
}

.z-project-viewer_close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 50px;
  color: #fff;
  cursor: pointer;
}

.z-project-viewer_title {
  font-size: 17px;
  line-height: 19.72px;
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  margin-top: 30px;
  margin-bottom: 3px;
}

@media (max-width: 1199.98px) {
  .z-project-viewer_title {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .z-project-viewer_title {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .z-project-viewer_title {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.z-project-viewer_list {
  padding: 0;
  list-style: none;
}

.z-project-viewer_body {
  color: #fff;
  max-width: 100vw;
  font-size: 25px;
  line-height: 29px;
}

@media (max-width: 1199.98px) {
  .z-project-viewer_body {
    font-size: 22.5px;
    line-height: 22.5px;
  }
}

@media (max-width: 991.98px) {
  .z-project-viewer_body {
    font-size: 20px;
    line-height: 20px;
  }
}

@media (max-width: 575.98px) {
  .z-project-viewer_body {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.z-project-viewer_navs .swiper-button-prev, .z-project-viewer_navs .swiper-button-next {
  background-color: #80BC00;
  text-align: center;
  width: 45px;
  height: 45px;
}

.z-project-viewer_navs .swiper-button-prev:after, .z-project-viewer_navs .swiper-button-next:after {
  font-size: 20px;
}

.z-project-viewer_navs .swiper-button-disabled {
  background-color: #333333;
  opacity: 1;
}

@media (min-width: 992px) {
  .z-project-viewer_navs {
    position: absolute;
    right: -30px;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }
  .z-project-viewer_navs .swiper-button-prev, .z-project-viewer_navs .swiper-button-next {
    position: static;
  }
  .z-project-viewer_navs .swiper-button-next:before {
    content: "";
    display: block;
    width: 35px;
    height: 1px;
    background-color: #fff;
    bottom: 45px;
    left: 0;
    z-index: 1;
    position: absolute;
  }
  .z-project-viewer_navs .swiper-button-next {
    margin-top: 0;
  }
}

.we-section_title {
  font-size: 100px;
  line-height: 70px;
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  color: #80BC00;
}

@media (max-width: 1199.98px) {
  .we-section_title {
    font-size: 80px;
    line-height: 80px;
  }
}

@media (max-width: 991.98px) {
  .we-section_title {
    font-size: 80px;
    line-height: 56px;
  }
}

@media (max-width: 767.98px) {
  .we-section_title {
    font-size: 60px;
    line-height: 42px;
  }
}

@media (max-width: 575.98px) {
  .we-section_title {
    font-size: 40px;
    line-height: 28px;
  }
}

.we-section_title span {
  font-size: 50px;
  line-height: 50px;
  color: #636569;
}

@media (max-width: 1199.98px) {
  .we-section_title span {
    font-size: 40px;
    line-height: 40px;
  }
}

@media (max-width: 991.98px) {
  .we-section_title span {
    font-size: 40px;
    line-height: 40px;
  }
}

@media (max-width: 767.98px) {
  .we-section_title span {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .we-section_title span {
    font-size: 30px;
    line-height: 30px;
  }
}

.we-section_text {
  max-width: 500px;
}

.we-section_subtitle {
  font-size: 20px;
  line-height: 23.2px;
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  color: #80BC00;
}

@media (max-width: 1199.98px) {
  .we-section_subtitle {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .we-section_subtitle {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .we-section_subtitle {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.z-footer {
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
  flex: 0 0 300px;
  max-width: 960px;
  color: #fff;
  background-color: #636569;
}

@media (max-width: 1199.98px) {
  .z-footer {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .z-footer {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .z-footer {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .z-footer {
    flex: auto;
  }
}

.z-footer_title {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 35px;
  line-height: 35px;
}

@media (max-width: 1199.98px) {
  .z-footer_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 991.98px) {
  .z-footer_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 767.98px) {
  .z-footer_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .z-footer_title {
    font-size: 30px;
    line-height: 30px;
  }
}

.z-footer_title span {
  color: #C3C6C8;
}

.z-footer_phone {
  font-size: 30px;
  line-height: 34.8px;
}

@media (max-width: 1199.98px) {
  .z-footer_phone {
    font-size: 27px;
    line-height: 27px;
  }
}

@media (max-width: 991.98px) {
  .z-footer_phone {
    font-size: 24px;
    line-height: 24px;
  }
}

@media (max-width: 575.98px) {
  .z-footer_phone {
    font-size: 18px;
    line-height: 18.56px;
  }
}

.z-footer_end {
  background-color: #80BC00;
}

.z-footer_privacy {
  font-size: 15px;
  line-height: 17.4px;
  color: #C3C6C8;
  padding: 20px 0;
  position: relative;
}

@media (max-width: 1199.98px) {
  .z-footer_privacy {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .z-footer_privacy {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .z-footer_privacy {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.z-footer_privacy:after {
  content: "";
  display: block;
  position: absolute;
  left: -32px;
  top: -10px;
  height: 4px;
  width: 110%;
  background-color: #fff;
}

.z-footer a {
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .z-footer a {
    transition: none;
  }
}

.z-footer a:hover {
  color: #80BC00;
}

.z-contact_title {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
  font-size: 75px;
  line-height: 75px;
}

@media (max-width: 1199.98px) {
  .z-contact_title {
    font-size: 60px;
    line-height: 60px;
  }
}

@media (max-width: 991.98px) {
  .z-contact_title {
    font-size: 60px;
    line-height: 60px;
  }
}

@media (max-width: 767.98px) {
  .z-contact_title {
    font-size: 45px;
    line-height: 45px;
  }
}

@media (max-width: 575.98px) {
  .z-contact_title {
    font-size: 30px;
    line-height: 30px;
  }
}

.z-phone {
  display: inline-block;
  position: relative;
}

.z-phone_sun {
  position: absolute;
  top: 125px;
  right: 100px;
  animation: spin 8s linear infinite;
}

.z-cloudcomputing {
  display: inline-block;
  position: relative;
}

.z-cloudcomputing_cloud {
  position: absolute;
  left: 55%;
  top: -80px;
  animation: float 5s ease-in-out infinite;
}

.z-cloudcomputing_screen {
  position: relative;
  z-index: 1;
}

.z-shoppingcar {
  position: relative;
}

.z-shoppingcar_car {
  animation: shopcar .6s ease-in-out infinite;
}

.z-shoppingcar_w1 {
  position: absolute;
  bottom: -25px;
  left: 105px;
  animation: wheels .6s ease-in-out infinite;
}

.z-shoppingcar_w2 {
  position: absolute;
  bottom: -14px;
  right: 75px;
  animation: wheels .6s ease-in-out infinite;
}

.z-shoppingcar_lines {
  position: absolute;
  left: -60px;
  top: 55px;
  animation: lines .6s ease-in-out infinite;
}

.z-shoppingcar_pointer {
  position: relative;
  left: 140px;
  animation: float 4s ease-in-out infinite;
}

.z-fingerprint {
  position: relative;
  transform: scale(0.7);
}

.z-fingerprint_c1 {
  position: absolute;
  animation: spin 6s linear infinite;
  left: 10px;
  top: 133px;
}

.z-fingerprint_c2 {
  position: absolute;
  animation: spin 16s linear infinite reverse;
  left: -8px;
  top: 116px;
}

.k-scalable-content {
  max-width: 100%;
}

.k-scalable-content_container {
  width: max-content;
}

@keyframes lines {
  50% {
    transform: skewX(3deg) scaleX(1.01);
    transform-origin: right;
  }
}

@keyframes shopcar {
  50% {
    transform: skewX(-3deg);
  }
}

@keyframes wheels {
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.home-page .logo-panel {
  position: relative;
  background: #80BC00;
  min-height: 85px;
}

@media (max-width: 991.98px) {
  .home-page .logo-panel {
    position: fixed;
    z-index: 1000;
  }
}

.home-page .logo-panel_logo {
  width: 300px;
}

@media (max-width: 991.98px) {
  .home-page .logo-panel_logo {
    width: 60px;
  }
}

.home-page .logo-panel_icons {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
}

.home-page .logo-panel_icons a {
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .home-page .logo-panel_icons a {
    transition: none;
  }
}

.home-page .logo-panel_icons a:hover {
  opacity: .6;
}

.home-page .logo-panel_cta {
  position: absolute;
  bottom: 20px;
  right: 40px;
}

.home-page .main-banner {
  background: 50% center no-repeat;
  background-size: cover;
}

@media (max-width: 1199.98px) {
  .home-page .main-banner {
    height: 100vh;
  }
}

.home-page .main-banner_title {
  font-size: 110px;
  line-height: 77px;
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  color: #fff;
  line-height: 100px;
}

@media (max-width: 1199.98px) {
  .home-page .main-banner_title {
    font-size: 88px;
    line-height: 88px;
  }
}

@media (max-width: 991.98px) {
  .home-page .main-banner_title {
    font-size: 88px;
    line-height: 61.6px;
  }
}

@media (max-width: 767.98px) {
  .home-page .main-banner_title {
    font-size: 66px;
    line-height: 46.2px;
  }
}

@media (max-width: 575.98px) {
  .home-page .main-banner_title {
    font-size: 44px;
    line-height: 30.8px;
  }
}

.home-page .main-banner_title--stroked {
  font-size: 120px;
  line-height: 84px;
  -webkit-text-stroke: 2px white;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1199.98px) {
  .home-page .main-banner_title--stroked {
    font-size: 96px;
    line-height: 96px;
  }
}

@media (max-width: 991.98px) {
  .home-page .main-banner_title--stroked {
    font-size: 96px;
    line-height: 67.2px;
  }
}

@media (max-width: 767.98px) {
  .home-page .main-banner_title--stroked {
    font-size: 72px;
    line-height: 50.4px;
  }
}

@media (max-width: 575.98px) {
  .home-page .main-banner_title--stroked {
    font-size: 48px;
    line-height: 33.6px;
  }
}

.home-page .main-banner_icon {
  margin-top: 40px;
  transform: translateX(10px) scale(1.5);
}

.home-page .main-banner_vertical-title {
  text-align: right;
}

.home-page .main-banner_vertical-title img {
  height: 100vh;
}

@media (max-width: 1199.98px) {
  .home-page .main-banner_vertical-title img {
    margin-top: 90px;
    height: calc(100vh - 250px);
  }
}

@media (min-width: 992px) {
  .home-page .new-section {
    background: url(../imgs/bg-portfolio.png) right 90% no-repeat;
  }
}

.home-page .new-section_title {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
  font-size: 105px;
  line-height: 84px;
}

@media (max-width: 1199.98px) {
  .home-page .new-section_title {
    font-size: 84px;
    line-height: 84px;
  }
}

@media (max-width: 991.98px) {
  .home-page .new-section_title {
    font-size: 84px;
    line-height: 67.2px;
  }
}

@media (max-width: 767.98px) {
  .home-page .new-section_title {
    font-size: 63px;
    line-height: 50.4px;
  }
}

@media (max-width: 575.98px) {
  .home-page .new-section_title {
    font-size: 42px;
    line-height: 33.6px;
  }
}

.home-page .new-section_title span {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 113px;
  line-height: 90.4px;
}

@media (max-width: 1199.98px) {
  .home-page .new-section_title span {
    font-size: 90.4px;
    line-height: 90.4px;
  }
}

@media (max-width: 991.98px) {
  .home-page .new-section_title span {
    font-size: 90.4px;
    line-height: 72.32px;
  }
}

@media (max-width: 767.98px) {
  .home-page .new-section_title span {
    font-size: 67.8px;
    line-height: 54.24px;
  }
}

@media (max-width: 575.98px) {
  .home-page .new-section_title span {
    font-size: 45.2px;
    line-height: 36.16px;
  }
}

.home-page .new-section_subtitle {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  line-height: 25px;
  color: #80BC00;
}

@media (max-width: 1199.98px) {
  .home-page .new-section_subtitle {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 991.98px) {
  .home-page .new-section_subtitle {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 767.98px) {
  .home-page .new-section_subtitle {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .home-page .new-section_subtitle {
    font-size: 30px;
    line-height: 30px;
  }
}

.home-page .new-section_text {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
}

@media (max-width: 1199.98px) {
  .home-page .new-section_text {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .home-page .new-section_text {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .home-page .new-section_text {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.home-page .new-section_carousel {
  height: 100vh;
}

@media (max-width: 991.98px) {
  .home-page .new-section_carousel {
    height: auto;
  }
}

.home-page .new-section_navigation .swiper-button-prev, .home-page .new-section_navigation .swiper-button-next {
  opacity: .6;
}

.home-page .new-section_navigation .swiper-button-disabled {
  opacity: .4;
}

@media (min-width: 992px) {
  .home-page .new-section_navigation {
    position: absolute;
    right: -60px;
    bottom: 130px;
    display: flex;
    flex-direction: column;
  }
  .home-page .new-section_navigation .swiper-button-prev, .home-page .new-section_navigation .swiper-button-next {
    position: static;
  }
  .home-page .new-section_navigation .swiper-button-prev {
    transform: rotate(90deg);
  }
  .home-page .new-section_navigation .swiper-button-next {
    margin-top: 30px;
    transform: rotate(90deg);
  }
}

.home-page .contact-section {
  position: relative;
}

.home-page .contact-section:after {
  left: -80px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #636569;
  content: "";
  position: absolute;
  width: 8px;
  height: 70%;
}

.home-page .services-section_title {
  font-size: 30px;
  line-height: 30px;
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  color: #80BC00;
}

@media (max-width: 1199.98px) {
  .home-page .services-section_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 991.98px) {
  .home-page .services-section_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 767.98px) {
  .home-page .services-section_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .home-page .services-section_title {
    font-size: 30px;
    line-height: 30px;
  }
}

.home-page .services-section_subtitle {
  font-size: 50px;
  line-height: 50px;
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
}

@media (max-width: 1199.98px) {
  .home-page .services-section_subtitle {
    font-size: 40px;
    line-height: 40px;
  }
}

@media (max-width: 991.98px) {
  .home-page .services-section_subtitle {
    font-size: 40px;
    line-height: 40px;
  }
}

@media (max-width: 767.98px) {
  .home-page .services-section_subtitle {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .home-page .services-section_subtitle {
    font-size: 30px;
    line-height: 30px;
  }
}

.home-page .services-section_subtitle span:nth-child(2) {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 90px;
  line-height: 90px;
}

@media (max-width: 1199.98px) {
  .home-page .services-section_subtitle span:nth-child(2) {
    font-size: 72px;
    line-height: 72px;
  }
}

@media (max-width: 991.98px) {
  .home-page .services-section_subtitle span:nth-child(2) {
    font-size: 72px;
    line-height: 72px;
  }
}

@media (max-width: 767.98px) {
  .home-page .services-section_subtitle span:nth-child(2) {
    font-size: 54px;
    line-height: 54px;
  }
}

@media (max-width: 575.98px) {
  .home-page .services-section_subtitle span:nth-child(2) {
    font-size: 36px;
    line-height: 36px;
  }
}

.home-page .services-section_subtitle span:nth-child(4) {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  font-size: 70px;
  line-height: 70px;
}

@media (max-width: 1199.98px) {
  .home-page .services-section_subtitle span:nth-child(4) {
    font-size: 56px;
    line-height: 56px;
  }
}

@media (max-width: 991.98px) {
  .home-page .services-section_subtitle span:nth-child(4) {
    font-size: 56px;
    line-height: 56px;
  }
}

@media (max-width: 767.98px) {
  .home-page .services-section_subtitle span:nth-child(4) {
    font-size: 42px;
    line-height: 42px;
  }
}

@media (max-width: 575.98px) {
  .home-page .services-section_subtitle span:nth-child(4) {
    font-size: 30px;
    line-height: 30px;
  }
}

.home-page .services-section_subtitle span:nth-child(5) {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  font-size: 70px;
  line-height: 70px;
}

@media (max-width: 1199.98px) {
  .home-page .services-section_subtitle span:nth-child(5) {
    font-size: 56px;
    line-height: 56px;
  }
}

@media (max-width: 991.98px) {
  .home-page .services-section_subtitle span:nth-child(5) {
    font-size: 56px;
    line-height: 56px;
  }
}

@media (max-width: 767.98px) {
  .home-page .services-section_subtitle span:nth-child(5) {
    font-size: 42px;
    line-height: 42px;
  }
}

@media (max-width: 575.98px) {
  .home-page .services-section_subtitle span:nth-child(5) {
    font-size: 30px;
    line-height: 30px;
  }
}

.home-page .services-section_banner {
  background: url("../imgs/banner-services.png") 0 0 repeat-y;
  background-size: 100%;
  animation-name: animatedBackground;
  animation-duration: 150s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.home-page .services-section_service {
  font-size: 18px;
  line-height: 20.88px;
  background-color: #fff;
}

@media (max-width: 1199.98px) {
  .home-page .services-section_service {
    font-size: 16.2px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .home-page .services-section_service {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .home-page .services-section_service {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.home-page .services-section_service_title {
  font-size: 30px;
  line-height: 30px;
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  color: #80BC00;
  margin: 0;
}

@media (max-width: 1199.98px) {
  .home-page .services-section_service_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 991.98px) {
  .home-page .services-section_service_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 767.98px) {
  .home-page .services-section_service_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .home-page .services-section_service_title {
    font-size: 30px;
    line-height: 30px;
  }
}

.home-page .services-section_service .circle-button, .home-page .services-section_service .z-project_mask_button, .home-page .services-section_service .company-page .brochure-section_button, .company-page .home-page .services-section_service .brochure-section_button {
  right: 10px;
  bottom: 10px;
}

@media (max-width: 991.98px) {
  .home-page .services-section_icon img {
    width: 100px;
  }
}

.home-page .services-section_points-list {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 45px;
  line-height: 52.2px;
  -webkit-text-stroke: 1px #636569;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1199.98px) {
  .home-page .services-section_points-list {
    font-size: 40.5px;
    line-height: 40.5px;
  }
}

@media (max-width: 991.98px) {
  .home-page .services-section_points-list {
    font-size: 36px;
    line-height: 36px;
  }
}

@media (max-width: 575.98px) {
  .home-page .services-section_points-list {
    font-size: 27px;
    line-height: 27px;
  }
}

.home-page .services-section_points-list li:last-child {
  -webkit-text-stroke: 0 #fff;
  -webkit-text-fill-color: #80BC00;
}

.home-page .blog-section_title {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 60px;
  line-height: 60px;
}

@media (max-width: 1199.98px) {
  .home-page .blog-section_title {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 991.98px) {
  .home-page .blog-section_title {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 767.98px) {
  .home-page .blog-section_title {
    font-size: 36px;
    line-height: 36px;
  }
}

@media (max-width: 575.98px) {
  .home-page .blog-section_title {
    font-size: 30px;
    line-height: 30px;
  }
}

.home-page .blog-section_post {
  position: relative;
  display: flex;
  padding: 10px 10px 7px 10px;
  margin-left: -5px;
  transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .home-page .blog-section_post {
    transition: none;
  }
}

.home-page .blog-section_post:hover {
  color: #fff;
  background-color: #636569;
}

@media (max-width: 767.98px) {
  .home-page .blog-section_post {
    align-items: center;
    flex-direction: column-reverse;
  }
}

.home-page .blog-section_post_date {
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 17.4px;
  color: #C5C6C7;
}

@media (max-width: 1199.98px) {
  .home-page .blog-section_post_date {
    font-size: 12px;
    line-height: 13.92px;
  }
}

@media (max-width: 991.98px) {
  .home-page .blog-section_post_date {
    font-size: 12px;
    line-height: 13.92px;
  }
}

@media (max-width: 575.98px) {
  .home-page .blog-section_post_date {
    font-size: 12px;
    line-height: 13.92px;
  }
}

.home-page .blog-section_post_title {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  font-size: 23px;
  line-height: 26.68px;
}

@media (max-width: 1199.98px) {
  .home-page .blog-section_post_title {
    font-size: 20.7px;
    line-height: 20.7px;
  }
}

@media (max-width: 991.98px) {
  .home-page .blog-section_post_title {
    font-size: 18.4px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .home-page .blog-section_post_title {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.home-page .blog-section_post_icon {
  position: absolute;
  bottom: 1rem;
}

@media (max-width: 767.98px) {
  .home-page .blog-section_post_icon {
    display: none;
  }
}

.company-page .title-section_title span:nth-child(1) {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  font-size: 100px;
  line-height: 100px;
}

@media (max-width: 1199.98px) {
  .company-page .title-section_title span:nth-child(1) {
    font-size: 80px;
    line-height: 80px;
  }
}

@media (max-width: 991.98px) {
  .company-page .title-section_title span:nth-child(1) {
    font-size: 80px;
    line-height: 80px;
  }
}

@media (max-width: 767.98px) {
  .company-page .title-section_title span:nth-child(1) {
    font-size: 60px;
    line-height: 60px;
  }
}

@media (max-width: 575.98px) {
  .company-page .title-section_title span:nth-child(1) {
    font-size: 40px;
    line-height: 40px;
  }
}

.company-page .title-section_title span:nth-child(3) {
  font-size: 120px;
  line-height: 84px;
  -webkit-text-stroke: 1px #333333;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1199.98px) {
  .company-page .title-section_title span:nth-child(3) {
    font-size: 96px;
    line-height: 96px;
  }
}

@media (max-width: 991.98px) {
  .company-page .title-section_title span:nth-child(3) {
    font-size: 96px;
    line-height: 67.2px;
  }
}

@media (max-width: 767.98px) {
  .company-page .title-section_title span:nth-child(3) {
    font-size: 72px;
    line-height: 50.4px;
  }
}

@media (max-width: 575.98px) {
  .company-page .title-section_title span:nth-child(3) {
    font-size: 48px;
    line-height: 33.6px;
  }
}

.company-page .title-section_title span:nth-child(5) {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 65px;
  line-height: 65px;
}

@media (max-width: 1199.98px) {
  .company-page .title-section_title span:nth-child(5) {
    font-size: 52px;
    line-height: 52px;
  }
}

@media (max-width: 991.98px) {
  .company-page .title-section_title span:nth-child(5) {
    font-size: 52px;
    line-height: 52px;
  }
}

@media (max-width: 767.98px) {
  .company-page .title-section_title span:nth-child(5) {
    font-size: 39px;
    line-height: 39px;
  }
}

@media (max-width: 575.98px) {
  .company-page .title-section_title span:nth-child(5) {
    font-size: 30px;
    line-height: 30px;
  }
}

.company-page .title-section_title span:nth-child(7) {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 120px;
  line-height: 120px;
}

@media (max-width: 1199.98px) {
  .company-page .title-section_title span:nth-child(7) {
    font-size: 96px;
    line-height: 96px;
  }
}

@media (max-width: 991.98px) {
  .company-page .title-section_title span:nth-child(7) {
    font-size: 96px;
    line-height: 96px;
  }
}

@media (max-width: 767.98px) {
  .company-page .title-section_title span:nth-child(7) {
    font-size: 72px;
    line-height: 72px;
  }
}

@media (max-width: 575.98px) {
  .company-page .title-section_title span:nth-child(7) {
    font-size: 48px;
    line-height: 48px;
  }
}

.company-page .title-section_title span:nth-child(9) {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 80px;
  line-height: 80px;
}

@media (max-width: 1199.98px) {
  .company-page .title-section_title span:nth-child(9) {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 991.98px) {
  .company-page .title-section_title span:nth-child(9) {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 767.98px) {
  .company-page .title-section_title span:nth-child(9) {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 575.98px) {
  .company-page .title-section_title span:nth-child(9) {
    font-size: 32px;
    line-height: 32px;
  }
}

.company-page .title-section_title span:nth-child(10) {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 80px;
  line-height: 80px;
}

@media (max-width: 1199.98px) {
  .company-page .title-section_title span:nth-child(10) {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 991.98px) {
  .company-page .title-section_title span:nth-child(10) {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 767.98px) {
  .company-page .title-section_title span:nth-child(10) {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 575.98px) {
  .company-page .title-section_title span:nth-child(10) {
    font-size: 32px;
    line-height: 32px;
  }
}

.company-page .banner-section {
  font-family: 'Caveat';
  font-weight: normal;
  font-style: normal;
  font-size: 64px;
  line-height: 64px;
  color: #fff;
  max-width: 1070px;
  flex: 0 0 1070px;
}

@media (max-width: 1199.98px) {
  .company-page .banner-section {
    font-size: 51.2px;
    line-height: 51.2px;
  }
}

@media (max-width: 991.98px) {
  .company-page .banner-section {
    font-size: 51.2px;
    line-height: 51.2px;
  }
}

@media (max-width: 767.98px) {
  .company-page .banner-section {
    font-size: 38.4px;
    line-height: 38.4px;
  }
}

@media (max-width: 575.98px) {
  .company-page .banner-section {
    font-size: 30px;
    line-height: 30px;
  }
}

.company-page .banner-section_banner {
  background: url(../imgs/bg-empresa.png) center center no-repeat;
  background-size: cover;
}

.company-page .banner-section_weare {
  height: 42vh;
}

.company-page .banner-section_zipvisual {
  height: 58vh;
}

.company-page .banner-section_title {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  font-size: 100px;
  line-height: 100px;
  color: #fff;
}

@media (max-width: 1199.98px) {
  .company-page .banner-section_title {
    font-size: 80px;
    line-height: 80px;
  }
}

@media (max-width: 991.98px) {
  .company-page .banner-section_title {
    font-size: 80px;
    line-height: 80px;
  }
}

@media (max-width: 767.98px) {
  .company-page .banner-section_title {
    font-size: 60px;
    line-height: 60px;
  }
}

@media (max-width: 575.98px) {
  .company-page .banner-section_title {
    font-size: 40px;
    line-height: 40px;
  }
}

.company-page .banner-section_title_stroked {
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;
}

.company-page .brochure-section_message {
  font-family: 'Caveat';
  font-weight: normal;
  font-style: normal;
  font-size: 80px;
  line-height: 80px;
  color: #fff;
  background: url(../imgs/bg-brochure.png) center center no-repeat;
  background-size: cover;
  width: 80vh;
  max-width: 800px;
  max-height: 800px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1199.98px) {
  .company-page .brochure-section_message {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 991.98px) {
  .company-page .brochure-section_message {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 767.98px) {
  .company-page .brochure-section_message {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 575.98px) {
  .company-page .brochure-section_message {
    font-size: 32px;
    line-height: 32px;
  }
}

@media (max-width: 991.98px) {
  .company-page .brochure-section_message {
    width: 80vw;
    height: 80vw;
  }
}

.company-page .brochure-section_message_min {
  font-size: 55px;
  line-height: 55px;
  position: relative;
  right: 50%;
}

@media (max-width: 1199.98px) {
  .company-page .brochure-section_message_min {
    font-size: 44px;
    line-height: 44px;
  }
}

@media (max-width: 991.98px) {
  .company-page .brochure-section_message_min {
    font-size: 44px;
    line-height: 44px;
  }
}

@media (max-width: 767.98px) {
  .company-page .brochure-section_message_min {
    font-size: 33px;
    line-height: 33px;
  }
}

@media (max-width: 575.98px) {
  .company-page .brochure-section_message_min {
    font-size: 30px;
    line-height: 30px;
  }
}

.company-page .brochure-section_button {
  font-size: 18px;
  line-height: 20.88px;
  background-color: #fff;
  color: #636569;
  flex-direction: column;
  display: inline-flex;
}

@media (max-width: 1199.98px) {
  .company-page .brochure-section_button {
    font-size: 16.2px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .company-page .brochure-section_button {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .company-page .brochure-section_button {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.company-page .objectives-section {
  background-color: #80BC00;
  color: #fff;
}

.company-page .objectives-section_title {
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  font-size: 80px;
  line-height: 80px;
}

@media (max-width: 1199.98px) {
  .company-page .objectives-section_title {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 991.98px) {
  .company-page .objectives-section_title {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 767.98px) {
  .company-page .objectives-section_title {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 575.98px) {
  .company-page .objectives-section_title {
    font-size: 32px;
    line-height: 32px;
  }
}

.company-page .objectives-section_list {
  line-height: 2rem;
  margin-left: 1.5rem;
}

.company-page .mv-section_title {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
  font-size: 60px;
  line-height: 60px;
}

@media (max-width: 1199.98px) {
  .company-page .mv-section_title {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 991.98px) {
  .company-page .mv-section_title {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 767.98px) {
  .company-page .mv-section_title {
    font-size: 36px;
    line-height: 36px;
  }
}

@media (max-width: 575.98px) {
  .company-page .mv-section_title {
    font-size: 30px;
    line-height: 30px;
  }
}

.contact-page .begin-section {
  color: #80BC00;
}

.contact-page .begin-section_title {
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  font-size: 35px;
  line-height: 28px;
}

@media (max-width: 1199.98px) {
  .contact-page .begin-section_title {
    font-size: 28px;
    line-height: 28px;
  }
}

@media (max-width: 991.98px) {
  .contact-page .begin-section_title {
    font-size: 28px;
    line-height: 22.4px;
  }
}

@media (max-width: 767.98px) {
  .contact-page .begin-section_title {
    font-size: 25px;
    line-height: 20px;
  }
}

@media (max-width: 575.98px) {
  .contact-page .begin-section_title {
    font-size: 25px;
    line-height: 20px;
  }
}

.contact-page .begin-section_phone {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 65px;
  line-height: 65px;
}

@media (max-width: 1199.98px) {
  .contact-page .begin-section_phone {
    font-size: 52px;
    line-height: 52px;
  }
}

@media (max-width: 991.98px) {
  .contact-page .begin-section_phone {
    font-size: 52px;
    line-height: 52px;
  }
}

@media (max-width: 767.98px) {
  .contact-page .begin-section_phone {
    font-size: 39px;
    line-height: 39px;
  }
}

@media (max-width: 575.98px) {
  .contact-page .begin-section_phone {
    font-size: 30px;
    line-height: 30px;
  }
}

.contact-page .begin-section_icon {
  font-size: 70px;
  line-height: 81.2px;
}

@media (max-width: 1199.98px) {
  .contact-page .begin-section_icon {
    font-size: 63px;
    line-height: 63px;
  }
}

@media (max-width: 991.98px) {
  .contact-page .begin-section_icon {
    font-size: 56px;
    line-height: 56px;
  }
}

@media (max-width: 575.98px) {
  .contact-page .begin-section_icon {
    font-size: 42px;
    line-height: 42px;
  }
}

.contact-page .begin-section_email {
  font-family: 'Roboto Cn';
  font-weight: normal;
  font-style: normal;
  font-size: 35px;
  line-height: 40.6px;
}

@media (max-width: 1199.98px) {
  .contact-page .begin-section_email {
    font-size: 31.5px;
    line-height: 31.5px;
  }
}

@media (max-width: 991.98px) {
  .contact-page .begin-section_email {
    font-size: 28px;
    line-height: 28px;
  }
}

@media (max-width: 575.98px) {
  .contact-page .begin-section_email {
    font-size: 21px;
    line-height: 21px;
  }
}

.contact-page .map-section {
  padding: 100px 180px;
  background-color: #636569;
}

@media (max-width: 991.98px) {
  .contact-page .map-section {
    padding: 15px 20px;
  }
}

.contact-page .map-section_map {
  border: 10px solid #80BC00;
  border-radius: 30px;
}

@media (max-width: 991.98px) {
  .contact-page .map-section_map {
    height: 400px;
  }
}

.design-page .title {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  font-size: 130px;
  line-height: 71.5px;
  -webkit-text-stroke: 1px #636569;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1199.98px) {
  .design-page .title {
    font-size: 104px;
    line-height: 104px;
  }
}

@media (max-width: 991.98px) {
  .design-page .title {
    font-size: 104px;
    line-height: 57.2px;
  }
}

@media (max-width: 767.98px) {
  .design-page .title {
    font-size: 78px;
    line-height: 42.9px;
  }
}

@media (max-width: 575.98px) {
  .design-page .title {
    font-size: 52px;
    line-height: 28.6px;
  }
}

.design-page .title span {
  font-size: 70px;
  line-height: 42px;
  -webkit-text-fill-color: #636569;
}

@media (max-width: 1199.98px) {
  .design-page .title span {
    font-size: 56px;
    line-height: 56px;
  }
}

@media (max-width: 991.98px) {
  .design-page .title span {
    font-size: 56px;
    line-height: 33.6px;
  }
}

@media (max-width: 767.98px) {
  .design-page .title span {
    font-size: 42px;
    line-height: 25.2px;
  }
}

@media (max-width: 575.98px) {
  .design-page .title span {
    font-size: 30px;
    line-height: 18px;
  }
}

.design-page .bg-zip {
  background: url("../imgs/bg-design-content.png") center center no-repeat;
}

.design-page .bg-zip2 {
  background: url("../imgs/bg-design-content2.png") center center no-repeat;
}

.design-page .banner-section {
  background: url("../imgs/bg-design.png") center center no-repeat;
  background-size: cover;
  min-height: 150px;
}

.design-page .banner-section_image {
  position: absolute;
  bottom: 15px;
  left: -100px;
}

@media (max-width: 991.98px) {
  .design-page .banner-section_image {
    display: none;
  }
}

.design-page .banner-section-2 {
  background: url("../imgs/bg-design-other.png") center center no-repeat;
  background-size: cover;
  min-height: 300px;
}

.design-page .brochure-button {
  width: 150px;
  height: 150px;
  flex-direction: column;
}

.design-page .brochure-button > .z-icon {
  margin-top: 1rem;
  font-size: 30px;
}

.design-page .bars-section {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 110%;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .design-page .bars-section {
    position: static;
    width: 130%;
  }
}

.design-page .bars-section .bar {
  height: 15px;
  margin-top: 10px;
}

.design-page .bars-section .bar_c {
  background-color: #009BDD;
}

.design-page .bars-section .bar_m {
  background-color: #D90080;
}

.design-page .bars-section .bar_y {
  background-color: #FFEE00;
}

.design-page .bars-section .bar_k {
  background-color: #1F130F;
}

.development-page .title-section_title {
  font-size: 120px;
  line-height: 84px;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
}

@media (max-width: 1199.98px) {
  .development-page .title-section_title {
    font-size: 96px;
    line-height: 96px;
  }
}

@media (max-width: 991.98px) {
  .development-page .title-section_title {
    font-size: 96px;
    line-height: 67.2px;
  }
}

@media (max-width: 767.98px) {
  .development-page .title-section_title {
    font-size: 72px;
    line-height: 50.4px;
  }
}

@media (max-width: 575.98px) {
  .development-page .title-section_title {
    font-size: 48px;
    line-height: 33.6px;
  }
}

.development-page .title-section_title_stroked {
  -webkit-text-stroke: 1px #333333;
  -webkit-text-fill-color: transparent;
}

.development-page .title-section_subtitle {
  font-size: 80px;
  line-height: 80px;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
}

@media (max-width: 1199.98px) {
  .development-page .title-section_subtitle {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 991.98px) {
  .development-page .title-section_subtitle {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 767.98px) {
  .development-page .title-section_subtitle {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 575.98px) {
  .development-page .title-section_subtitle {
    font-size: 32px;
    line-height: 32px;
  }
}

.development-page .computer-section {
  background: url("../imgs/bg-development-computer.png") 160px center no-repeat;
  background-size: cover;
  min-height: 150px;
}

@media (max-width: 1199.98px) {
  .development-page .computer-section {
    background: url("../imgs/bg-development-computer.png") center center no-repeat;
    background-size: cover;
  }
}

.development-page .computer-section_mouse {
  position: absolute;
  left: 0;
  top: 40%;
}

@media (max-width: 1199.98px) {
  .development-page .computer-section_mouse {
    display: none;
  }
}

.development-page .apps-section {
  background: url("../imgs/bg-development-apps.png") center center no-repeat;
  background-size: cover;
}

.development-page .apps-section_icons {
  font-size: 40px;
  color: #fff;
}

.development-page .brochure-section {
  background: url("../imgs/bg-development-responsive.png") center center no-repeat;
  background-size: cover;
}

.marketing-page .title-section_title {
  font-size: 110px;
  line-height: 88px;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
}

@media (max-width: 1199.98px) {
  .marketing-page .title-section_title {
    font-size: 88px;
    line-height: 88px;
  }
}

@media (max-width: 991.98px) {
  .marketing-page .title-section_title {
    font-size: 88px;
    line-height: 70.4px;
  }
}

@media (max-width: 767.98px) {
  .marketing-page .title-section_title {
    font-size: 66px;
    line-height: 52.8px;
  }
}

@media (max-width: 575.98px) {
  .marketing-page .title-section_title {
    font-size: 44px;
    line-height: 35.2px;
  }
}

.marketing-page .title-section_title_stroked {
  -webkit-text-stroke: 1px #333333;
  -webkit-text-fill-color: transparent;
}

.marketing-page .banner-section {
  background: url("../imgs/bg-marketing.png") center center no-repeat;
  background-size: cover;
  min-height: 150px;
}

.marketing-page .adwords-section {
  position: relative;
  background: url("../imgs/bg-awords.png") center center no-repeat;
  background-size: cover;
}

.marketing-page .adwords-section_icon {
  position: absolute;
  right: 50px;
  bottom: 50px;
}

@media (max-width: 991.98px) {
  .marketing-page .adwords-section_icon {
    opacity: .6;
  }
}

.marketing-page .media-section {
  animation: bgMediaSection infinite alternate 1.5s ease-in-out;
}

@media (max-width: 991.98px) {
  .marketing-page .media-section {
    animation: none;
  }
}

.marketing-page .seo-section {
  animation: bgSeoSection infinite alternate 1.5s ease-in-out;
}

@media (max-width: 991.98px) {
  .marketing-page .seo-section {
    animation: none;
  }
}

.marketing-page .marketing-section {
  animation: bgMarketingSection infinite alternate-reverse 1.5s ease-in-out;
}

@media (max-width: 991.98px) {
  .marketing-page .marketing-section {
    animation: none;
  }
}

.marketing-page .brochure-section {
  background: url("../imgs/bg-development-brochure.png") center center no-repeat;
  background-size: cover;
}

.portfolio-page .title-verticalbox {
  transform: rotate(270deg);
  white-space: nowrap;
}

@media (max-width: 991.98px) {
  .portfolio-page .title-verticalbox {
    transform: none;
    white-space: normal;
  }
}

.portfolio-page .title {
  font-size: 75px;
  line-height: 75px;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  -webkit-text-stroke: 1px #333333;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1199.98px) {
  .portfolio-page .title {
    font-size: 60px;
    line-height: 60px;
  }
}

@media (max-width: 991.98px) {
  .portfolio-page .title {
    font-size: 60px;
    line-height: 60px;
  }
}

@media (max-width: 767.98px) {
  .portfolio-page .title {
    font-size: 45px;
    line-height: 45px;
  }
}

@media (max-width: 575.98px) {
  .portfolio-page .title {
    font-size: 36px;
    line-height: 36px;
  }
}

.portfolio-page .subtitle {
  font-size: 40px;
  line-height: 40px;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
}

@media (max-width: 1199.98px) {
  .portfolio-page .subtitle {
    font-size: 32px;
    line-height: 32px;
  }
}

@media (max-width: 991.98px) {
  .portfolio-page .subtitle {
    font-size: 32px;
    line-height: 32px;
  }
}

@media (max-width: 767.98px) {
  .portfolio-page .subtitle {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .portfolio-page .subtitle {
    font-size: 30px;
    line-height: 30px;
  }
}

.portfolio-page .projects_carousel {
  height: 100vh;
}

@media (max-width: 991.98px) {
  .portfolio-page .projects_carousel {
    height: auto;
  }
}

.portfolio-page .projects-aside {
  background: url(../imgs/icon-portfolio-white.png) center right no-repeat #80BC00;
}

@media (max-width: 991.98px) {
  .portfolio-page .vertical-navigation .swiper-button-white, .portfolio-page .vertical-navigation .swiper-button-black {
    --swiper-navigation-color: $color-green;
  }
}

.portfolio-page .vertical-navigation .swiper-button-prev, .portfolio-page .vertical-navigation .swiper-button-next {
  opacity: .6;
}

.portfolio-page .vertical-navigation .swiper-button-disabled {
  opacity: .4;
}

@media (min-width: 992px) {
  .portfolio-page .vertical-navigation {
    position: absolute;
    right: -60px;
    bottom: 130px;
    display: flex;
    flex-direction: column;
  }
  .portfolio-page .vertical-navigation .swiper-button-prev, .portfolio-page .vertical-navigation .swiper-button-next {
    position: static;
  }
  .portfolio-page .vertical-navigation .swiper-button-prev {
    transform: rotate(90deg);
  }
  .portfolio-page .vertical-navigation .swiper-button-next {
    margin-top: 30px;
    transform: rotate(90deg);
  }
}

.hosting-page .title-section_title {
  font-size: 80px;
  line-height: 56px;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
}

@media (max-width: 1199.98px) {
  .hosting-page .title-section_title {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .title-section_title {
    font-size: 64px;
    line-height: 44.8px;
  }
}

@media (max-width: 767.98px) {
  .hosting-page .title-section_title {
    font-size: 48px;
    line-height: 33.6px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .title-section_title {
    font-size: 32px;
    line-height: 22.4px;
  }
}

.hosting-page .title-section_title_stroked {
  font-size: 120px;
  line-height: 120px;
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  -webkit-text-stroke: 1px #333333;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1199.98px) {
  .hosting-page .title-section_title_stroked {
    font-size: 96px;
    line-height: 96px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .title-section_title_stroked {
    font-size: 96px;
    line-height: 96px;
  }
}

@media (max-width: 767.98px) {
  .hosting-page .title-section_title_stroked {
    font-size: 72px;
    line-height: 72px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .title-section_title_stroked {
    font-size: 48px;
    line-height: 48px;
  }
}

.hosting-page .domains-availability-section {
  background: url(../imgs/bg-hosting-domains.png) center center no-repeat;
  background-size: cover;
  color: #fff;
}

.hosting-page .domains-availability-section_form .el-form-item {
  display: inline-block;
  max-width: 100%;
}

.hosting-page .domains-availability-section_form .el-input {
  width: 350px;
  max-width: 100%;
}

.hosting-page .domains-availability-section_form .el-input__inner {
  width: 350px;
  max-width: 100%;
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
}

@media (max-width: 1199.98px) {
  .hosting-page .domains-availability-section_form .el-input__inner {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .domains-availability-section_form .el-input__inner {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .domains-availability-section_form .el-input__inner {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .domains-availability-section_form .el-select .el-input {
  width: 130px;
  max-width: 100%;
}

@media (max-width: 991.98px) {
  .hosting-page .domains-availability-section_form .domains-availability-section_form_name {
    width: calc(50% - 50px);
  }
  .hosting-page .domains-availability-section_form .domains-availability-section_form_extension {
    width: calc(53% - 50px);
  }
  .hosting-page .domains-availability-section_form .domains-availability-section_form_extension .el-select {
    width: calc(100%);
  }
}

.hosting-page .domains-availability-section_table {
  color: #80BC00;
  width: 100%;
  max-width: 496px;
}

.hosting-page .domains-availability-section_table_status {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
  width: 130px;
}

@media (max-width: 1199.98px) {
  .hosting-page .domains-availability-section_table_status {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .domains-availability-section_table_status {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .domains-availability-section_table_status {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .domains-availability-section_table_status--unavailable {
  color: #c6ccd7;
}

.hosting-page .domains-availability-section_price {
  width: 496px;
  max-width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.hosting-page .domains-availability-section_price_title {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
}

@media (max-width: 1199.98px) {
  .hosting-page .domains-availability-section_price_title {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .domains-availability-section_price_title {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .domains-availability-section_price_title {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .domains-availability-section_price_number {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 25px;
  line-height: 29px;
}

@media (max-width: 1199.98px) {
  .hosting-page .domains-availability-section_price_number {
    font-size: 22.5px;
    line-height: 22.5px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .domains-availability-section_price_number {
    font-size: 20px;
    line-height: 20px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .domains-availability-section_price_number {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .domains-availability-section .el-checkbox__label {
  color: #fff;
}

.hosting-page .domains-availability-section .is-checked .el-checkbox__label {
  color: #80BC00;
}

.hosting-page .domains-availability-section_cb .el-checkbox__label {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
  color: #fff;
}

@media (max-width: 1199.98px) {
  .hosting-page .domains-availability-section_cb .el-checkbox__label {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .domains-availability-section_cb .el-checkbox__label {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .domains-availability-section_cb .el-checkbox__label {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .domains-availability-section_arrow-btn {
  border: none;
  background-color: #fff;
  padding: 0 20px;
  font-size: 32px;
  line-height: 45px;
  border-radius: 10px;
}

@media (max-width: 991.98px) {
  .hosting-page .domains-availability-section_arrow-btn {
    margin-top: 50px;
  }
}

.hosting-page .domains-section_title {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 80px;
  line-height: 80px;
  color: #80BC00;
}

@media (max-width: 1199.98px) {
  .hosting-page .domains-section_title {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .domains-section_title {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 767.98px) {
  .hosting-page .domains-section_title {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .domains-section_title {
    font-size: 32px;
    line-height: 32px;
  }
}

.hosting-page .domains-section_title span {
  color: #636569;
}

.hosting-page .domains-section_table {
  max-width: 100%;
  width: 420px;
}

.hosting-page .domains-section_table thead {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
}

@media (max-width: 1199.98px) {
  .hosting-page .domains-section_table thead {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .domains-section_table thead {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .domains-section_table thead {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .domains-section_table thead td {
  padding-bottom: 20px;
}

.hosting-page .domains-section_table tbody tr {
  border-bottom: 1px solid #636569;
}

.hosting-page .domains-section_table tbody td {
  padding: 4px 0;
}

.hosting-page .domains-section_terms {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 16.24px;
}

@media (max-width: 1199.98px) {
  .hosting-page .domains-section_terms {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .domains-section_terms {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .domains-section_terms {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .domains-section_requested {
  width: 485px;
  max-width: 100%;
  height: 200px;
  overflow-y: auto;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #636569;
}

.hosting-page .domains-section_requested_title {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
}

@media (max-width: 1199.98px) {
  .hosting-page .domains-section_requested_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .domains-section_requested_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 767.98px) {
  .hosting-page .domains-section_requested_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .domains-section_requested_title {
    font-size: 30px;
    line-height: 30px;
  }
}

.hosting-page .domains-section_requested_domains {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
  list-style: none;
  color: #80BC00;
  padding: 0;
}

@media (max-width: 1199.98px) {
  .hosting-page .domains-section_requested_domains {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .domains-section_requested_domains {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .domains-section_requested_domains {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .domains-section_requested_domains_remove {
  color: #636569;
  cursor: pointer;
  font-size: 15px;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .hosting-page .domains-section_requested_domains_remove {
    transition: none;
  }
}

.hosting-page .domains-section_requested_domains_remove:hover {
  color: #80BC00;
}

.hosting-page .hosting-section {
  flex: 0 0 1200px;
  max-width: 1200px;
}

@media (max-width: 991.98px) {
  .hosting-page .hosting-section {
    flex: auto;
    width: 100%;
    max-width: 100%;
  }
}

.hosting-page .hosting-section_title {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 80px;
  line-height: 80px;
  color: #80BC00;
}

@media (max-width: 1199.98px) {
  .hosting-page .hosting-section_title {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .hosting-section_title {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 767.98px) {
  .hosting-page .hosting-section_title {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .hosting-section_title {
    font-size: 32px;
    line-height: 32px;
  }
}

.hosting-page .hosting-section_title span {
  color: #636569;
}

.hosting-page .hosting-section_last-radio .el-radio__input + .el-radio__label {
  font-size: 20px;
  line-height: 23.2px;
  color: #636569;
}

@media (max-width: 1199.98px) {
  .hosting-page .hosting-section_last-radio .el-radio__input + .el-radio__label {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .hosting-section_last-radio .el-radio__input + .el-radio__label {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .hosting-section_last-radio .el-radio__input + .el-radio__label {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .hosting-section_last-radio .el-radio__input {
  margin-bottom: 4px;
}

.hosting-page .hosting-section_terms {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 16.24px;
}

@media (max-width: 1199.98px) {
  .hosting-page .hosting-section_terms {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .hosting-section_terms {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .hosting-section_terms {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .hosting-section_terms_download {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 13.92px;
}

@media (max-width: 1199.98px) {
  .hosting-page .hosting-section_terms_download {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .hosting-section_terms_download {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .hosting-section_terms_download {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .hosting-section_terms_download_icon {
  font-size: 23px;
}

.hosting-page .hosting-section_btn {
  border: none;
  background-color: #F7931E;
  border-radius: 15px;
  color: #fff;
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
  padding: 15px 20px;
}

@media (max-width: 1199.98px) {
  .hosting-page .hosting-section_btn {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .hosting-section_btn {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .hosting-section_btn {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .hosting-section .hosting-plan {
  width: 190px;
  text-align: center;
}

.hosting-page .hosting-section .hosting-plan_header {
  background-color: #80BC00;
  color: #fff;
}

.hosting-page .hosting-section .hosting-plan_header_title {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  font-size: 23px;
  line-height: 23px;
}

@media (max-width: 1199.98px) {
  .hosting-page .hosting-section .hosting-plan_header_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .hosting-section .hosting-plan_header_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 767.98px) {
  .hosting-page .hosting-section .hosting-plan_header_title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .hosting-section .hosting-plan_header_title {
    font-size: 30px;
    line-height: 30px;
  }
}

.hosting-page .hosting-section .hosting-plan_header_title span {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
}

.hosting-page .hosting-section .hosting-plan_header_size {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 25px;
  line-height: 25px;
}

@media (max-width: 1199.98px) {
  .hosting-page .hosting-section .hosting-plan_header_size {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .hosting-section .hosting-plan_header_size {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 767.98px) {
  .hosting-page .hosting-section .hosting-plan_header_size {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .hosting-section .hosting-plan_header_size {
    font-size: 30px;
    line-height: 30px;
  }
}

.hosting-page .hosting-section .hosting-plan_footer {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 25px;
  line-height: 29px;
  background-color: #636569;
}

@media (max-width: 1199.98px) {
  .hosting-page .hosting-section .hosting-plan_footer {
    font-size: 22.5px;
    line-height: 22.5px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .hosting-section .hosting-plan_footer {
    font-size: 20px;
    line-height: 20px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .hosting-section .hosting-plan_footer {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .hosting-section .hosting-plan_footer .el-radio__input + .el-radio__label {
  font-size: 25px;
  line-height: 29px;
  color: #fff;
}

@media (max-width: 1199.98px) {
  .hosting-page .hosting-section .hosting-plan_footer .el-radio__input + .el-radio__label {
    font-size: 22.5px;
    line-height: 22.5px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .hosting-section .hosting-plan_footer .el-radio__input + .el-radio__label {
    font-size: 20px;
    line-height: 20px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .hosting-section .hosting-plan_footer .el-radio__input + .el-radio__label {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.hosting-page .hosting-section .hosting-plan_footer .el-radio__input {
  margin-bottom: 4px;
}

.hosting-page .hosting-section .hosting-plan_included {
  font-size: 13px;
  line-height: 15.08px;
  text-align: left;
}

@media (max-width: 1199.98px) {
  .hosting-page .hosting-section .hosting-plan_included {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .hosting-page .hosting-section .hosting-plan_included {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .hosting-page .hosting-section .hosting-plan_included {
    font-size: 16px;
    line-height: 18.56px;
  }
}

.services-page .page-1500 {
  flex: 0 0 1500px;
  max-width: 1500px;
}

@media (max-width: 991.98px) {
  .services-page .page-1500 {
    flex: auto;
  }
}

.services-page .title-section {
  background: #636569;
  color: #fff;
}

.services-page .title-section_title {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  font-size: 105px;
  line-height: 105px;
}

@media (max-width: 1199.98px) {
  .services-page .title-section_title {
    font-size: 84px;
    line-height: 84px;
  }
}

@media (max-width: 991.98px) {
  .services-page .title-section_title {
    font-size: 84px;
    line-height: 84px;
  }
}

@media (max-width: 767.98px) {
  .services-page .title-section_title {
    font-size: 63px;
    line-height: 63px;
  }
}

@media (max-width: 575.98px) {
  .services-page .title-section_title {
    font-size: 42px;
    line-height: 42px;
  }
}

.services-page .title-section_title span {
  font-size: 80px;
  line-height: 48px;
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1199.98px) {
  .services-page .title-section_title span {
    font-size: 64px;
    line-height: 64px;
  }
}

@media (max-width: 991.98px) {
  .services-page .title-section_title span {
    font-size: 64px;
    line-height: 38.4px;
  }
}

@media (max-width: 767.98px) {
  .services-page .title-section_title span {
    font-size: 48px;
    line-height: 28.8px;
  }
}

@media (max-width: 575.98px) {
  .services-page .title-section_title span {
    font-size: 32px;
    line-height: 19.2px;
  }
}

.services-page .title-section_title .circle-button, .services-page .title-section_title .z-project_mask_button, .services-page .title-section_title .company-page .brochure-section_button, .company-page .services-page .title-section_title .brochure-section_button {
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  width: 50px;
  height: 50px;
  position: relative;
  top: -20px;
}

.services-page .title-section_icons {
  position: absolute;
  left: 15px;
  bottom: 10px;
}

.services-page .services-section_service {
  height: 25vh;
  display: flex;
  align-items: center;
}

.services-page .services-section_service_title {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-size: 40px;
  line-height: 24px;
  color: #fff;
}

@media (max-width: 1199.98px) {
  .services-page .services-section_service_title {
    font-size: 32px;
    line-height: 32px;
  }
}

@media (max-width: 991.98px) {
  .services-page .services-section_service_title {
    font-size: 32px;
    line-height: 19.2px;
  }
}

@media (max-width: 767.98px) {
  .services-page .services-section_service_title {
    font-size: 30px;
    line-height: 18px;
  }
}

@media (max-width: 575.98px) {
  .services-page .services-section_service_title {
    font-size: 30px;
    line-height: 18px;
  }
}

.services-page .services-section_service_title span {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 28px;
  line-height: 28px;
}

@media (max-width: 1199.98px) {
  .services-page .services-section_service_title span {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 991.98px) {
  .services-page .services-section_service_title span {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 767.98px) {
  .services-page .services-section_service_title span {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .services-page .services-section_service_title span {
    font-size: 30px;
    line-height: 30px;
  }
}

.newsletter-section {
  background: url("../imgs/bg-envelop.png") left center no-repeat;
}

@media (max-width: 991.98px) {
  .newsletter-section {
    background: none;
  }
}

.newsletter-section::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 90vh;
  width: 10px;
  background-color: #80BC00;
}

@media (max-width: 991.98px) {
  .newsletter-section::after {
    display: none;
  }
}

.newsletter-section_title {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 60px;
  line-height: 60px;
}

@media (max-width: 1199.98px) {
  .newsletter-section_title {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 991.98px) {
  .newsletter-section_title {
    font-size: 48px;
    line-height: 48px;
  }
}

@media (max-width: 767.98px) {
  .newsletter-section_title {
    font-size: 36px;
    line-height: 36px;
  }
}

@media (max-width: 575.98px) {
  .newsletter-section_title {
    font-size: 30px;
    line-height: 30px;
  }
}

.newsletter-section_subtitle {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 23.2px;
}

@media (max-width: 1199.98px) {
  .newsletter-section_subtitle {
    font-size: 18px;
    line-height: 18.56px;
  }
}

@media (max-width: 991.98px) {
  .newsletter-section_subtitle {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@media (max-width: 575.98px) {
  .newsletter-section_subtitle {
    font-size: 16px;
    line-height: 18.56px;
  }
}

@keyframes bgMediaSection {
  0% {
    background: url("../imgs/bg-media-1.png") 10% 10% no-repeat, url("../imgs/bg-media-2.png") 19% 32% no-repeat, url("../imgs/bg-media-3.png") 39% 20% no-repeat, url("../imgs/bg-media-4.png") 47% 30% no-repeat, url("../imgs/bg-media-5.png") 60% 14% no-repeat, url("../imgs/bg-media-6.png") 70% 44% no-repeat, url("../imgs/bg-media-7.png") 90% 21% no-repeat;
  }
  100% {
    background: url("../imgs/bg-media-1.png") 10% 13% no-repeat, url("../imgs/bg-media-2.png") 19% 30% no-repeat, url("../imgs/bg-media-3.png") 39% 21% no-repeat, url("../imgs/bg-media-4.png") 47% 30.5% no-repeat, url("../imgs/bg-media-5.png") 60% 12% no-repeat, url("../imgs/bg-media-6.png") 70% 47% no-repeat, url("../imgs/bg-media-7.png") 90% 23% no-repeat;
  }
}

@keyframes bgSeoSection {
  0% {
    background: url("../imgs/bg-seo-animated.png") 50% 46% no-repeat;
  }
  100% {
    background: url("../imgs/bg-seo-animated.png") 50% 50% no-repeat;
  }
}

@keyframes bgMarketingSection {
  0% {
    background: url("../imgs/bg-marketing-animated.png") 50% 46% no-repeat;
  }
  100% {
    background: url("../imgs/bg-marketing-animated.png") 50% 50% no-repeat;
  }
}

@keyframes animatedBackground {
  0% {
    background-position-y: 0;
  }
  100% {
    background-position-y: 9990px;
  }
}

@keyframes backgroundScaled {
  0% {
    background-size: auto 105%;
  }
  100% {
    background-size: auto 150%;
  }
}
