@font-face {
	font-family: 'Roboto';
	src: url('Roboto-Medium.woff2') format('woff2'),
	url('Roboto-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@mixin font-roboto-medium {
	font-family: 'Roboto';
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('Roboto-Bold.woff2') format('woff2'),
	url('Roboto-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@mixin font-roboto-bold {
	font-family: 'Roboto';
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('Roboto-Thin.woff2') format('woff2'),
	url('Roboto-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@mixin font-roboto-thin {
	font-family: 'Roboto';
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('Roboto-Black.woff2') format('woff2'),
	url('Roboto-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@mixin font-roboto-black {
	font-family: 'Roboto';
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto Cn';
	src: url('Roboto-Condensed.woff2') format('woff2'),
	url('Roboto-Condensed.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@mixin font-roboto-condensed {
	font-family: 'Roboto Cn';
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('Roboto-Regular.woff2') format('woff2'),
	url('Roboto-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@mixin font-roboto {
	font-family: 'Roboto';
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto Cn';
	src: url('Roboto-BoldCondensed.woff2') format('woff2'),
	url('Roboto-BoldCondensed.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@mixin font-roboto-bold-condensed {
	font-family: 'Roboto';
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('Roboto-Light.woff2') format('woff2'),
	url('Roboto-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@mixin font-roboto-light {
	font-family: 'Roboto';
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Caveat';
	src: url('Caveat-Regular.woff2') format('woff2'),
	url('Caveat-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@mixin font-caveat{
	font-family: 'Caveat';
	font-weight: normal;
	font-style: normal;
}
