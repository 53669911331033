//$grid-breakpoints: (
//	// Extra small screen / phone 0
//		xs: 0,
//	// Small screen / phone 576
//		sm: 576px,
//	// Medium screen / tablet 768
//		md: 768px,
//	// Large screen / desktop 992
//		lg: 992px,
//	// Extra large screen / wide desktop 1200
//		xl: 1200px
//);
//
//$container-max-widths: (
//		sm: 540px,
//		md: 720px,
//		lg: 1280px,
//		xl: 1720px
//);

$spacer: 2rem;
$spacers: (
    6: ($spacer * 4.5),
    7: ($spacer * 6),
    8: ($spacer * 7.5),
    9: ($spacer * 9),
    10: ($spacer * 10.5),
);
//$grid-gutter-width: 0;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid";

/**
Fix for layout in horizontal mode
 */
@include media-breakpoint-up(md) {
  .container-fluid, .container {
    padding: 0;
  }
  .row {
    margin: 0;
  }
  .col {
    padding: 0;
  }
}


.layout-horizontal {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    width: 100%;
    height: auto;
    overflow: auto;
  }

  &_container {
    width: calc(100vh + 18px); //Fix to hide the scroll
    height: 100vw;
    transform: rotate(-90deg) translateX(-100vh);
    transform-origin: top left;
    overflow-y: scroll;
    overflow-x: hidden;
    @include media-breakpoint-down(md) {
      transform: none;
      height: auto;
      width: 100%;
      overflow: hidden;
    }
  }

  &_wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    transform: rotate(90deg) translateY(-100vh) scale(1);
    transform-origin: top left;
    @include media-breakpoint-down(md) {
      width: 100%;
      height: auto;
      transform: none;
      display: block;
    }
  }

  &_page {
    flex: 0 0 1920px;
    max-width: 1920px;
    @include media-breakpoint-down(md) {
      flex: auto;
    }
  }

  //960
  &_middle-page {
    flex: 0 0 960px;
    max-width: 960px;
    @include media-breakpoint-down(md) {
      flex: auto;
    }
  }

  &_600-page {
    flex: 0 0 600px;
    max-width: 600px;
    @include media-breakpoint-down(md) {
      max-width: none;
      flex: auto;
    }
  }
}

//html, body {
//	margin: 0;
//	padding: 0;
//	min-width: 100%;
//	min-height: 100%;
//}
//
//.container{
//	width: 100vh;
//	height: 100vw;
//	transform: rotate(-90deg) translateX(-100vh);
//	transform-origin: top left;
//	overflow-x: hidden;
//	top: 0;
//	left: 0;
//	position: absolute;
//}
//
//.view {
//	width: 100vw;
//	height: 100vh;
//	display: flex;
//	transform: rotate(90deg) translateY(-100vh);
//	transform-origin: top left;
//}
//
//.page {
//	flex: 0 0 auto;
//	width: 100vw;
//	height: 100vh;
//}
